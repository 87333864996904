import styled from "styled-components";

const SECTION_DATA_1 = [
  {
    title: ["국가공인 정비소", "차량진단"],
    subtitle: "카몬은 정확합니다.",
    imgUrl: "/images/main/2-1.png",
  },
  {
    title: ["감가 無,", "최고가 낙찰 경매"],
    subtitle: "카몬은 똘똘합니다.",
    imgUrl: "/images/main/2-2.png",
  },
  {
    title: ["수수료?", "전혀 없습니다!"],
    subtitle: "카몬에 진단 예약만 하세요!",
    imgUrl: "/images/main/2-3.png",
  },
];

const SECTION_DATA_2 = [
  {
    title: "예약하고",
    imgUrl: "/images/main/3-1.png",
  },
  {
    title: "방문하면",
    imgUrl: "/images/main/3-2.png",
  },
  {
    title: "끝!",
    imgUrl: "/images/main/3-3.png",
  },
];

export const ServicePerformanceCheck = () => {
  return (
    <>
      <Section $background="#F8F9FB">
        <Wrapper>
          <Title $color="#535353">
            카몬, <FontYellow>가격</FontYellow>만 올리다.
          </Title>
          <ShadowContainer width="11rem" $headercolor="#757575">
            {SECTION_DATA_1.map((data, i) => (
              <div key={i}>
                <h5>
                  {data.title[0]}
                  <br />
                  {data.title[1]}
                </h5>
                <p>{data.subtitle}</p>
                <img alt="" src={data.imgUrl} />
              </div>
            ))}
          </ShadowContainer>
        </Wrapper>
      </Section>

      <Section $background="#F8F9FB">
        <Wrapper>
          <Title $color="#535353">간단히 정리해 드립니다!</Title>
          <SubTitle>세 컷이면 설명 끝!</SubTitle>
          <ShadowContainer $imgPb="1rem">
            {SECTION_DATA_2.map((data, i) => (
              <div key={i}>
                <img alt="" src={data.imgUrl} />
                <h5>{data.title}</h5>
              </div>
            ))}
          </ShadowContainer>
        </Wrapper>
      </Section>
    </>
  );
};

const Section = styled.section`
  display: flex;
  justify-content: center;

  background: ${(props) => props.$background || "white"};
  padding: ${(props) => props.$padding || "5.5rem 3rem"};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }

  span {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  overflow: hidden;
`;

const Title = styled.h1`
  color: ${(props) => props.$color || "black"} !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  padding: 0.5rem 0;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem !important;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 2rem;
  }

  span,
  p {
    font-size: 1.4rem;
    font-weight: 400 !important;
    line-height: 1.7;
    padding-bottom: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 1.3rem;
      line-height: 1.5;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  span {
    font-weight: bold !important;
    padding-right: 2rem;
    color: var(--red-color);
  }
`;

const FontYellow = styled.span`
  color: var(--yellow-color);
`;

const SubTitle = styled.h1`
  color: var(--yellow-color);
  font-size: 1.5rem;
  font-weight: bolder !important;
  padding: 1rem 0 2.5rem 0;
  line-height: 1.4;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
  }
`;

const ShadowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  div {
    width: 22rem;
    aspect-ratio: 1/1;
    margin: 1rem 1.2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: white;
    border-radius: 1rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.05),
      0 8px 8px rgba(0, 0, 0, 0.05), 0 16px 16px rgba(0, 0, 0, 0.05);

    @media only screen and (max-width: 600px) {
      width: 96%;
      margin: 1rem 0;
    }
  }

  img {
    width: 50%;
    padding-bottom: ${(props) => props.$imgPb || "0"};
  }

  h5 {
    font-size: 2rem;
    font-weight: bolder;
    line-height: 1.2;
    color: ${(props) => props.$headercolor || "black"};
    padding-bottom: 1rem;

    @media only screen and (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 1.3rem;
    font-weight: bolder;
    color: var(--yellow-color);
    padding-bottom: 1rem;

    @media only screen and (max-width: 800px) {
      font-size: 1.1rem;
    }
  }
`;
