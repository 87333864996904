import React from "react";

export default function PalBubble({ chat, lineIdx, bbIdx, fontStyles }) {
  return (
    <div
      data-line-idx={lineIdx}
      data-bb-for={"pal"}
      data-bb-idx={bbIdx}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0 8px",
      }}
    >
      <div className={"speech-bubble-other"} style={{ ...fontStyles }}>
        {chat}
      </div>
    </div>
  );
}
