import styled from "styled-components";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const NewsList = ({ newsData }) => {
  return (
    <ListContainer>
      {newsData.map((news) => (
        <ListItem key={news.newsId} onClick={() => window.open(news.link, "_blank")}>
          <h3>{news.title}</h3>
          <h5>{news.lastModifiedAt.split(" ")[0]}</h5>
          <p>
            <ArrowForwardIcon color="gray.500" />
          </p>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default NewsList;

const ListContainer = styled.div`
  border-top: 2px solid black;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 30px;
  border-bottom: 1px solid gainsboro;
  cursor: pointer;

  h3 {
    flex: 1;
    text-align: left;
  }

  h5 {
    color: #555555;
    font-size: 13px;
    font-weight: 400;
  }

  p {
    color: lightgray;
    padding-left: 20px;
  }

  &:hover {
    background-color: #f3f3f3;
  }
`;
