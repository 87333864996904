import {Header} from 'semantic-ui-react';
import {BASE_STYLES} from '../../../Consts';
import React from 'react';

export default function SectionHeader({header, subheader}) {
  return (
    <Header as={'h1'} textAlign={'center'}>
      <Header.Content className={'section-header'}>
        {header}
      </Header.Content>
      <div style={{padding: '3px 0'}} />
      <Header.Subheader className={'section-subheader'}>
        {subheader}
      </Header.Subheader>
      <div style={{padding: '8px 0'}} />
    </Header>
  );
}
