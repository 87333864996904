import _ from "lodash";
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Grid, Header, Icon, Input, Message, Modal, Segment, Table } from "semantic-ui-react";
import ReservationContext from "../../../../stores/ReservationContext";
import ReservationStepOuter from "../ReservationStepOuter";

import KakaoMap from "../../KakaoMap";

import axios from "axios";
import moment from "moment";
import "moment/locale/ko";
import DaumPostcode from "react-daum-postcode";
import { isDesktop, isMobile } from "react-device-detect";
import { DateInput } from "semantic-ui-calendar-react-yz";
import { BASE_STYLES } from "../../../../Consts";
import { CommonUtil } from "../../../../utils/CommonUtil";

const MY_STEP = 2;
const DATE_FORMAT = "YYYY-MM-DD";

const NOW = moment();
const todayWeekday = NOW.weekday();
const isTodayFriday = todayWeekday === 5;
const isOnDuty = NOW.hours() > 9 && NOW.hours() < 17;

const isTodaySaturday = todayWeekday === 6;
const isTodaySunday = todayWeekday === 0;

const MAX_COUNT = 15;
let START_DAY, MAX_DAY, HOLIDAYS;

function initReservationAvailableDays() {
  let compensation = 1;

  if (isTodayFriday) {
    if (isOnDuty) compensation = 1;
    else compensation = 4;
  } else if (isTodaySaturday) {
    compensation = 3;
  } else if (isTodaySunday) {
    compensation = 2;
  } else {
    if (!isOnDuty) compensation = 2;
  }

  let startDay = NOW.clone().add(compensation, "days");
  let endDay;
  let holidays = [];
  let realDayCounter = 0;

  for (let i = 0; i < MAX_COUNT; i++) {
    endDay = startDay.clone().add(i + realDayCounter, "days");

    if (endDay.weekday() === 0) {
      holidays.push(endDay.format(DATE_FORMAT));
      endDay.add(1, "days");
      realDayCounter++;
    }
  }

  START_DAY = startDay;
  MAX_DAY = endDay;
  HOLIDAYS = holidays;
}

initReservationAvailableDays();

export default function NewReservationStep2() {
  const { currentStep, resetStep, backToPrevStep, setToMyStep, goToNextStep, buildButtons } =
    useContext(ReservationContext);

  const location = useLocation();
  const { params, step1Data } = location.state || {};

  const [isLoading] = useState(false);
  const [isPostCodeOpened, setIsPostCodeOpened] = useState(false);
  const [carmonHolidays, setCarmonHolidays] = useState([]);

  const [holidayLoading, setHolidayLoading] = useState(false);

  const [centerIdleTimes] = useState([
    { time: "10:00", isAvailable: "Y", useWeekend: true },
    { time: "11:00", isAvailable: "Y", useWeekend: true },
    { time: "12:00", isAvailable: "N", useWeekend: true },
    { time: "13:00", isAvailable: "Y", useWeekend: true },
    { time: "14:00", isAvailable: "Y", useWeekend: false },
    { time: "15:00", isAvailable: "Y", useWeekend: false },
    { time: "16:00", isAvailable: "Y", useWeekend: false },
    { time: "17:00", isAvailable: "Y", useWeekend: false },
  ]);

  const [step2Data, setStep2Data] = useState({
    userAddress: location.state?.step2Data?.userAddress,
    center: null,
    reservationDate: location.state?.step2Data
      ? location.state?.step2Data.reservationDate
      : START_DAY.format(DATE_FORMAT),
    reservationTime: location.state?.step2Data?.reservationTime,
    point: location.state?.step2Data?.point,
    sido: location.state?.step2Data?.sido,
    sigungu: location.state?.step2Data?.sigungu,
  });

  const centerRef = useRef(null);

  useEffect(() => {
    if (_.isEmpty(step2Data.userAddress)) return;

    axios.get("apis/common/address/point", { params: { address: step2Data.userAddress } }).then(({ data }) => {
      setStep2Data((prevState) => {
        return {
          ...prevState,
          point: {
            x: data.x,
            y: data.y,
          },
        };
      });
    });
  }, [step2Data.userAddress]);

  useEffect(() => setToMyStep(MY_STEP), []);

  const getHolidayFromServer = async () => {
    try {
      const response = await axios.get("/apis/holidays");
      return response;
    } catch (error) {
      console.error("Error fetching holidays:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchHolidays = async () => {
      setHolidayLoading(true);
      const holidaysFromServer = await getHolidayFromServer();

      if (holidaysFromServer && holidaysFromServer.status === 200) {
        const data = holidaysFromServer.data;
        let tempHolidays = [];

        _.map(data, (o) => {
          const startDate = moment(o.startDate).format(DATE_FORMAT);
          const endDate = moment(o.endDate).format(DATE_FORMAT);
          const currentHolidays = CommonUtil.getDatesStartToLast(startDate, endDate);

          HOLIDAYS.push(...currentHolidays);
          setCarmonHolidays((prevState) => {
            return [...prevState, o];
          });
        });

        HOLIDAYS = _.sortBy(_.uniq(HOLIDAYS));

        //예약 가능 시작일자가 휴일에 포함되어있는 경우, 예약가능한 가장 빠른 일자로 설정
        if (_.includes(HOLIDAYS, moment(START_DAY).format(DATE_FORMAT))) {
          const firstDate = _.first(HOLIDAYS);
          const lastDate = _.last(HOLIDAYS);
          const allDays = CommonUtil.getDatesStartToLast(firstDate, lastDate);

          for (let i = 0; i < allDays.length; i++) {
            if (!_.includes(HOLIDAYS, allDays[i])) {
              START_DAY = moment(allDays[i]);
              setStep2Data((prevState) => {
                return {
                  ...prevState,
                  reservationDate: allDays[i],
                };
              });
              break;
            }
          }
        }
      }
      setHolidayLoading(false);
    };

    fetchHolidays();
  }, []);

  useLayoutEffect(() => {
    if (isDesktop) setTimeout(() => centerRef.current.focus(), 100);
  }, []);

  useEffect(() => {
    buildButtons([
      <Button
        key="btn-back-prev"
        size={isDesktop ? "large" : "small"}
        disabled={isLoading}
        onClick={() => {
          backToPrevStep({
            params,
            step1Data,
          });
        }}
      >
        <Icon name={"angle left"} fitted /> 이전
      </Button>,
      <Button
        key="btn-go-next"
        size={isDesktop ? "large" : "small"}
        color={"yellow"}
        disabled={
          !(
            !_.isEmpty(step2Data.userAddress) &&
            step2Data.reservationDate !== "" &&
            step2Data.reservationTime !== ""
          ) || isLoading
        }
        loading={isLoading}
        onClick={() => {
          goToNextStep({
            params,
            step1Data,
            step2Data,
          });
        }}
      >
        예약자 정보 입력 <Icon name={"" + "angle right"} fitted />
      </Button>,
    ]);
  }, [step2Data, isLoading]);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isLoading}>
      <Header as={isDesktop ? "h2" : "h3"}>
        <Header.Content>
          편한 시간에 방문하세요!
          <Header.Subheader>제 값 받고 "내 차 팔기"는 여기서부터 시작됩니다.</Header.Subheader>
        </Header.Content>
      </Header>

      <Header as="h3" dividing>
        <Icon name={"calendar check outline"} />
        <Header.Content>
          방문희망일시 선택
          <Header.Subheader>방문을 원하시는 날짜와 시간을 선택하세요.</Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row columns={"equal"}>
          <Grid.Column>
            <Segment basic loading={holidayLoading} style={{ padding: 0 }}>
              <DateInput
                pickerStyle={{ minWidth: "100%" }}
                inline={true}
                localization={"ko"}
                dateFormat={DATE_FORMAT}
                minDate={START_DAY.format(DATE_FORMAT)}
                maxDate={MAX_DAY.format(DATE_FORMAT)}
                value={step2Data?.reservationDate || START_DAY.format(DATE_FORMAT)}
                disable={HOLIDAYS}
                onChange={(e, { value }) =>
                  setStep2Data((prevState) => ({
                    ...prevState,
                    reservationDate: value,
                  }))
                }
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic loading={holidayLoading} style={{ padding: 0 }}>
              {!_.isEmpty(centerIdleTimes) ? (
                <Table celled fixed unstackable>
                  <Table.Header />
                  <Table.Body>
                    {_.map(_.chunk(centerIdleTimes, 4), (chunk, row) => (
                      <Table.Row textAlign={"center"} key={`TT_ROW${row}`}>
                        {_.map(_.times(4), (col) => {
                          const selectedDate = new Date(step2Data?.reservationDate);

                          return (
                            <Table.Cell
                              key={`TT_ROW${row}_COL${col}`}
                              style={{ height: 40, cursor: "pointer", verticalAlign: "middle" }}
                              selectable
                              active={step2Data?.reservationTime === chunk[col]?.time}
                              disabled={
                                chunk[col]?.isAvailable === "N" ||
                                (selectedDate.getDay() >= 6 && !chunk[col]?.useWeekend)
                              }
                              onClick={() => {
                                setStep2Data((prevState) => ({
                                  ...prevState,
                                  reservationTime: chunk[col]?.time,
                                }));
                              }}
                            >
                              {chunk[col]?.time}
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Message size={isDesktop ? "large" : "small"}>
                  <Message.Content>
                    <Message.Header>
                      <Icon name={"clock outline"} /> 정비소와 날짜를 선택하고 예약 가능한 시간을 확인해 보세요.
                    </Message.Header>
                    정비소를 변경하시면, 예약 시간을 다시 선택하셔야 합니다.
                  </Message.Content>
                </Message>
              )}
            </Segment>
            {carmonHolidays.length > 0 && (
              <Segment>
                <Header as="h3" dividing>
                  <Icon name={"bullhorn"} />
                  <Header.Content>휴무 안내</Header.Content>
                </Header>
                {_.map(carmonHolidays, (o, idx) => {
                  return (
                    <Message key={`holiday_msg${idx}`} color={"orange"} style={{ padding: "10px" }}>
                      <Header as={"h5"}>
                        <Header.Content>
                          {moment(o.startDate).format(DATE_FORMAT)} ~ {moment(o.endDate).format(DATE_FORMAT)}
                        </Header.Content>
                        <Header.Subheader style={{ color: "#666" }}>{o.description}</Header.Subheader>
                      </Header>
                    </Message>
                  );
                })}
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header as="h3" dividing>
        <Icon name={"map marker alternate"} />
        <Header.Content>
          주소 입력
          <Header.Subheader>
            입력하신 주소를 기준으로 카몬이 가장 가까운 정비소를 확인 후 안내드리겠습니다.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={16}>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Input
                    ref={centerRef}
                    style={{ flex: 1, fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                    fluid
                    value={step2Data.userAddress}
                    onChange={(e, { value }) => {
                      setStep2Data((prevState) => ({ ...prevState, userAddress: value }));
                    }}
                    readOnly={true}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.target.blur();
                        setIsPostCodeOpened(true);
                      }
                    }}
                    onClick={() => {
                      if (_.isEmpty(step2Data.userAddress)) setIsPostCodeOpened(true);
                    }}
                    icon={
                      step2Data.userAddress !== "" && (
                        <Icon
                          name="remove"
                          link
                          onClick={() =>
                            setStep2Data((prevState) => ({
                              ...prevState,
                              userAddress: "",
                            }))
                          }
                        />
                      )
                    }
                    placeholder="주소를 입력하고 조회하세요!"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button
                    fluid
                    icon={"search"}
                    size={"large"}
                    content={"검색"}
                    onClick={() => setIsPostCodeOpened(true)}
                    color={"yellow"}
                    style={{ margin: 0 }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <KakaoMap
              mapId={"reservation-step3-map"}
              latitude={step2Data?.point?.y}
              longitude={step2Data?.point?.x}
              draggable={!isMobile}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        closeIcon
        size={"small"}
        open={isPostCodeOpened}
        onOpen={() => setIsPostCodeOpened(true)}
        onClose={() => setIsPostCodeOpened(false)}
      >
        <Modal.Header>주소선택</Modal.Header>
        <Modal.Content style={{ padding: 0 }}>
          <DaumPostcode
            defaultQuery={step2Data.userAddress}
            onComplete={({ address, sido, sigungu }) => {
              setStep2Data((prevState) => ({ ...prevState, userAddress: address, sido, sigungu }));
              setIsPostCodeOpened(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </ReservationStepOuter>
  );
}
