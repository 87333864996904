import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Image } from "semantic-ui-react";

const CustomFooter = () => {
  const navigate = useNavigate();
  return (
    <StyledFooter className="container">
      <div>
        <ul>
          <Hover>
            <a href="#header">홈</a>
          </Hover>
          <Hover onClick={() => navigate("/aboutCompany")}>회사소개</Hover>
          <Hover onClick={() => navigate("/term")}>이용약관</Hover>
          <Hover onClick={() => navigate("/privacy")}>개인정보처리방침</Hover>
          <Hover>
            <Image
              src={"/images/icons/naver-blog.png"}
              style={{ width: "18px", height: "18px", marginTop: "3px", cursor: "pointer" }}
              onClick={() => window.open("https://blog.naver.com/car-monster", "_blank")}
            />
          </Hover>
          <Hover>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCyzRHfTywuEHqfCEsdtC6KQ"
              aria-label="YouTube"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 576 512"
                focusable="false"
                height="20px"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
              </svg>
            </a>
          </Hover>
        </ul>
        <ul>
          <li>회사명 : (주)카몬스터</li>
          <li>대표자 : 성백환</li>
          <li>주소 : 서울특별시 강남구 논현로8길 10-10 진원빌딩 3층 카몬스터</li>
          <li>이메일 : carmonster000@gmail.com</li>
          <li>전화 : 070-7576-4981 (평일 10:00 - 17:30)</li>
          <li>사업자등록번호 : 457-87-02409</li>
        </ul>
        <p>COPYRIGHT © (주)카몬스터 ALL RIGHTS RESERVED.</p>
      </div>
    </StyledFooter>
  );
};

export default CustomFooter;

const Container = styled.footer`
  display: flex;
  justify-content: center;
  & > div {
    flex: 1;
    max-width: 1280px;
    padding: 6rem 2rem;
  }
`;

const StyledFooter = styled(Container)`
  background: var(--darkgray-color);
  color: var(--white-color);

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.3rem;
  }
  ul:first-child {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--white-color);
  }
  ul li {
    padding-right: 2rem;
    padding-bottom: 0.3rem;
    line-height: 1.3;
  }
`;

const Hover = styled.li`
  a &:hover {
    cursor: pointer;
    color: var(--yellow-color);
  }
  &:hover {
    cursor: pointer;
    color: var(--yellow-color);
  }
`;
