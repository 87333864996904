import axios from 'axios';

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.timeout = 180000;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';

    return config;
  }, (error) => {
    console.log('request error catch from axios interceptor:', error);
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    console.log('response error catch from axios interceptor:', error);
    return Promise.reject(error);
  },
);
