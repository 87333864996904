import _ from 'lodash';

export const CommonUtil = {
  Currency: {
    format(price) {
      const formatter = new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW',
      });

      return formatter.format(price ? _.toNumber(price) : 0);
    },
  },
  Unit: {
    format(unit) {
      const formatter = new Intl.NumberFormat('ko-KR');

      return formatter.format(unit ? _.toNumber(unit) : 0);
    },
  },
  convertToNumber(str) {
    if (!isNaN(str)) return Number(str);
    else return str;
  },
  convertToCurrency(str) {
    if (!isNaN(str)) {
      const formatter = new Intl.NumberFormat('ko-KR');
      return formatter.format(Number(str));
    } else return str;
  },
  Validator: {
    validatePassword(password = '') {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
      return password !== undefined && password.trim() !== '' && regex.test(password);
    },
    validateCellPhone(phoneNumber = '') {
      const regex = /^01([016789])-([0-9]{3,4})-([0-9]{4})$/;
      return phoneNumber !== undefined && phoneNumber.trim() !== '' && regex.test(phoneNumber);
    },
    validateLicensePlateNumber(licensePlateNumber = '') {
      if (licensePlateNumber.length > 9) return false;

      if (licensePlateNumber.length === 8 && /^[가-힣]{2}\d{1}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 9 && /^[가-힣]{2}\d{2}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 7 && /^\d{2}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 8 && /^\d{3}[가-힣]\d{4}/.test(licensePlateNumber)) return true;

      return false;
    },
  },
  CellPhone: {
    format(phoneNumber = '') {
      let number = phoneNumber.replace(/[^0-9]/g, '');
      let length = number.length;
      let result = '';

      if (length < 4) return number;
      else if (length < 7) {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3);
      } else if (length < 11) {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3, 3);
        result += '-';
        result += number.substr(6);
      } else {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3, 4);
        result += '-';
        result += number.substr(7);
      }

      return result;
    },
  },
  getDatesStartToLast: (startDate, lastDate) => {
    const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
    const result = [];
    const curDate = new Date(startDate);
    while (curDate <= new Date(lastDate)) {
      result.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }

    return result;
  }
};
