export const ServiceTPA = () => {
  return (
    <>
      {/* TPA(Third Party Administer)란? 제 3자 관리 운영자로 국산,수입자동차의 품질 성늠점검 및 보증에 관련된 검사를
      원스톱으로 처리하는 운영관리 시스템을 말합니다. 수입자동차의 제조사, 판매사, 보험사와,소비자간의 상호이해에
      직접관계 없이 TPA(제 3자관리 운영자) 제공으로,객관성과 공정성을 확보 함으로써 이해 관계인 모두에게 공동이익을
      제공을 목적으로 하고 있습니다 ​ - 수입자동차 소비자 및 정비센터, 중고차딜러, 부품공급업체 등 권익보호 - 수입자동차
      평가, 보증수리, 진단, 부품조달 등 관련 사업 제공 */}
    </>
  );
};
