import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";
import "@/config/axios_config";
import "@/styles/NewApp.css";
import "@/styles/main.css";

import NotFound from "@/layout/pages/NotFound";
import Reservation from "@/layout/pages/Reservation";
import AboutCompany from "@/layout_new/pages/AboutCompany";
import AboutService from "@/layout_new/pages/AboutService";
import Community from "@/layout_new/pages/Community";
import Issue from "@/layout_new/pages/Issue";
import Main from "@/layout_new/pages/Main";
import Privacy from "@/layout_new/pages/Privacy";
import Term from "@/layout_new/pages/Term";
import Layout from "@/layout_new/Layout";

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<Main />} />
          <Route path="/aboutCompany" element={<AboutCompany />} />
          <Route path="/aboutService" element={<AboutService />} />
          <Route path="/community" element={<Community />} />
          <Route path="/issue" element={<Issue />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term" element={<Term />} />
        </Route>
        <Route path="reservation/*">
          <Route index element={<Navigate replace to={"/reservation/step1"} />} />
          <Route path={"step1"} element={<Reservation />} />
          <Route path={"step2"} element={<Reservation />} />
          <Route path={"step3"} element={<Reservation />} />
        </Route>
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
