import styled from "styled-components";
import { AspectRatio } from "@chakra-ui/react";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VideoGrid = () => (
  <GridContainer>
    <AspectRatio maxW="560px" ratio={16 / 9}>
      <iframe
        title="완전 비대면 내차팔기 카몬"
        src="https://www.youtube.com/embed/LcM3CJavN4U?controls=0"
        allowFullScreen
      />
    </AspectRatio>
    <AspectRatio maxW="560px" ratio={16 / 9}>
      <iframe
        title="정비소에서 내차팔기 카몬"
        src="https://www.youtube.com/embed/ree8Krtm7kI?controls=0"
        allowFullScreen
      />
    </AspectRatio>
  </GridContainer>
);

export default VideoGrid;
