import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import { PageHeader } from "@/layout_new/components/PageHeader";
import { ServiceCarmon } from "@/layout_new/components/aboutService/ServiceCarmon";
import { ServicePerformanceCheck } from "@/layout_new/components/aboutService/ServicePerformanceCheck";
import { ServiceTPA } from "@/layout_new/components/aboutService/ServiceTPA";

const AboutService = () => {
  const tabNames = ["데이터 기반 비대면 경매장", "차량 성능진단 프로그램", "TPA 프로그램"];

  return (
    <>
      <PageHeader title="서비스" subtitle="새로운 중고차 거래 문화를 만드는 카몬" />
      <Tabs mt="30px" position="relative" variant="unstyled">
        <TabList justifyContent="center">
          {tabNames.map((name, index) => (
            <Tab key={index}>{name}</Tab>
          ))}
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
        <TabPanels mt="50px">
          <TabPanel>
            <ServiceCarmon />
          </TabPanel>
          <TabPanel>
            <ServicePerformanceCheck />
          </TabPanel>
          <TabPanel>
            <ServiceTPA />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default AboutService;
