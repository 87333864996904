import { Container } from "semantic-ui-react";
import styled from "styled-components";

const Privacy = () => {
  return (
    <>
      <Container>
        <PrivacyContainer>
          <h3>개인정보처리방침</h3>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주소 등의 사항에 의하여 당해
                  개인을 알아볼 수 있는 부호, 문자, 음성, 음향 및 영상 등의 정보(당해 정보만으로는 특정 개인을 식별할 수
                  없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.
                </p>
              </li>
              <li>
                <p>
                  카몬(이하 ‘회사’)은 고객의 개인정보보호를 매우 중요시하며, 「개인정보보호법」,「정보통신망 이용 촉진
                  및 정보보호에 관한 법률」(이하 “정보통신망법”) 등 개인정보보호 관련 법률 및 하위 법령들을 준수하고
                  있습니다.
                </p>
              </li>
              <li>
                <p>
                  회사는 개인정보처리방침을 통하여 고객의 개인정보를 이용하는 목적과 방법, 귀하의 개인정보 보호를 위해
                  취하는 조치를 알려드립니다. 회사는 개인정보처리방침을 홈페이지 첫 화면에 공개함으로써 언제나 용이하게
                  보실 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  회사는 본 개인정보 처리방침을 개정하는데 필요한 절차를 정하고 있으며, 본 개인정보 처리방침을 관계
                  법령, 회사의 필요와 사회적 변화에 맞춰 변경할 수 있습니다. 본 개인정보 처리방침을 개정하는 경우 회사는
                  고객이 개정된 사항을 쉽게 알아볼 수 있도록 하고 있습니다.
                </p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>
                회사의 개인정보처리방침은 회사가 제공하는 “카몬스터” 관련 제반 서비스(홈페이지, 모바일 웹/앱 포함)이용에
                적용되며 다음과 같은 내용을 담고 있습니다.
              </strong>
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>홈페이지(모바일 웹, 앱 포함)를 통한 서비스 이용 예약, 전화를 통한 정보, 탁송요청</p>
              </li>
              <li>
                <p>협력 회사로부터의 제공</p>
              </li>
              <li>
                <p>생성정보 수집 툴을 통한 수집</p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보의 수집 항목 및 이용목적</strong>
              <br />
              회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  서비스 제공에 관한 계약 이행
                  <br />
                  컨텐츠 제공, 물품배송 또는 청구서 등 발송, 본인인증, 판매대금 입금
                </p>
              </li>
              <li>
                <p>
                  서비스 개발 및 마케팅
                  <br />
                  신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성
                  확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 이용자의 서비스이용에 대한 통계, 제휴 상품 및
                  서비스에 대한 제반 마케팅(대행포함) 활동 (EM, SMS, TM, DM 발송 등 마케팅, 판촉행사 및 이벤트, 사은행사
                  소개 등 포함)
                </p>
              </li>
              <li>
                <p>
                  수집한 개인정보의 이용목적 구분
                  <br />
                  이름 : 소유자 차량 검증을 위한 본인 확인절차에 이용
                  <br />
                  연락처(전화번호, 휴대전화번호) : 고지사항 전달 및 본인의사확인, 불만처리, 사용자 구분 등 원활한
                  의사소통 경로의 확보, 서비스 이용에 대한 통계분석, 마케팅 및 광고에 활용
                </p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보의 공유 및 제공</strong>
              <br />
              회사는 이용자의 <strong>“개인정보의 수집 항목 및 이용목적”</strong>
              에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
              이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
            </p>
          </div>
          <div className="ulist">
            <ul>
              <li>
                <p>
                  기타 법에 의해 요구된다고 선의로 판단되는 경우 (예. 관련법령에 의거 적법한 절차에 의한 정부/수사기관의
                  요청이 있는 경우 등) 서비스 이용에 필요한 목적을 위해 이용자의 개인정보에 대해서 제 3자에게 제공(별도
                  이용자의 동의 여부 확인)
                </p>
              </li>
              <li>
                <p>
                  회사는 사업의 전부 또는 일부 양도, 인수합병 등으로 서비스 제공자의 권리와 의무를 이전 또는 승계하는
                  경우에는 그 사실을 사전에 상세하게 고지하고, 개인정보 수집/이용 등에 대한 동의 철회의 선택권을
                  부여합니다.
                  <br />
                  이용자가 온라인상의 게시판 등을 통해 기재한 인적 사항을 제3자가 수집하는 경우가 있을 수 있으므로 이에
                  유의하시기 바랍니다. 이용자가 스스로 게시판 등을 통해 기재한 인적 사항과 관련하여 회사는 어떠한 책임도
                  지지 않습니다.
                </p>
              </li>
            </ul>
          </div>
          <table className="tableblock frame-all grid-all stretch">
            <thead>
              <tr>
                <th className="tableblock halign-left valign-top">제공대상</th>
                <th className="tableblock halign-left valign-top">제공정보의 이용목적, 제공항목</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">카몬 고객센터</p>
                </td>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">고객 유선 상담 시</p>
                </td>
              </tr>
              <tr>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">카몬 온라인 중개 입찰 회원사</p>
                </td>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">카몬 제휴 차량</p>
                </td>
              </tr>
              <tr>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">정비사</p>
                </td>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">차량진단 진행 시</p>
                </td>
              </tr>
              <tr>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">카몬 제휴 탁송 업체</p>
                </td>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">낙찰 후 차량 인도 시</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="paragraph">
            <p>
              <strong>개인정보의 보유 및 이용기간</strong>
              <br />
              개인정보를 제공받은 제 3자가 별도로 개인 정보 수집 및 이용에 대한 동의를 받으며, 보유 및 이용기간은 동의한
              내용에 따라 적용됨 이름, 주민등록번호(이전 등록 시), 휴대 전화번호, 주소, 3자 제공 동의 일자, 동의 여부
              제3자 제공동의 철회 또는 회원탈퇴 시까지
            </p>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보의 취급위탁</strong>
              <br />
              회사는 서비스 향상을 위해서 필요한 경우 및 기타 서비스 제공을 위해서 이용자의 개인정보를 외부에
              수집·보관·처리·이용·제공·관리·파기 등을 할 수 있도록 아래와 같이 업무를 위탁하여 운영하고 있습니다. 회사는
              위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의
              금지 및 사고 시의 책임부담 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관하여 이용자의
              권익을 보호하고 있습니다.
            </p>
          </div>
          <table className="tableblock frame-all grid-all stretch">
            <thead>
              <tr>
                <th className="tableblock halign-left valign-top">위탁업무내용</th>
                <th className="tableblock halign-left valign-top">수탁업체</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">데이터 분석 및 처리</p>
                </td>
                <td className="tableblock halign-left valign-top">
                  <p className="tableblock">Google Analytics</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="paragraph">
            <p>
              <strong>개인정보의 보유 및 이용기간</strong>
              <br />
              회사는 이용자가 회원으로서 서비스를 이용하는 동안 이용자의 개인정보를 보유 및 이용하며, 이용자가
              예약취소를 요청한 경우나 개인정보의 수집 및 이용목적을 달성하거나 보유 및 이용기간이 종료한 경우 또는
              사업폐지 등의 사유가 발생한 경우 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
              이유로 명시한 기간 동안 보존합니다.
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  회사 내부 방침에 의한 정보보유 사유
                  <br />
                  부정이용기록
                  <br />
                  보존 이유 : 부정 이용 방지
                  <br />
                  보존 기간 : 1년
                </p>
              </li>
              <li>
                <p>
                  관련법령에 의한 정보보유 사유
                  <br />
                  상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보존할 필요가 있는 경우
                  회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그
                  보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                </p>
                <div className="literalblock">
                  <div className="content">
                    <pre>
                      계약 또는 청약철회 등에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간
                      : 5년
                    </pre>
                  </div>
                </div>
                <div className="literalblock">
                  <div className="content">
                    <pre>
                      대금결제 및 재화 등의 공급에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 보존
                      기간 : 5년
                    </pre>
                  </div>
                </div>
                <div className="literalblock">
                  <div className="content">
                    <pre>
                      소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
                      보존 기간 : 3년
                    </pre>
                  </div>
                </div>
                <div className="literalblock">
                  <div className="content">
                    <pre>방문에 관한 기록 보존 이유 : 통신비밀보호법 보존 기간 : 3개월</pre>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보 파기절차 및 방법</strong>
              <br />
              회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 수집한 이용자의 개인정보의 수집 및
              이용목적이 달성되거나, 이용자가 회원탈퇴를 요청한 경우 지체 없이 파기하여 향후 어떠한 용도로도 열람 또는
              이용할 수 없도록 처리합니다. 단, “5. 개인정보의보유 및 이용기간 가, 나”와 같은 예외 경우를 두고 있습니다.
              <br />
              회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  파기절차
                  <br />
                  이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
                  서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(5. 보유 및 이용기간 참조) 일정 기간
                  동안 저장된 후 파기됩니다.
                  <br />동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                </p>
              </li>
              <li>
                <p>
                  파기방법
                  <br />
                  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된
                  개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                </p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>만14세 미만 아동의 개인정보보호</strong>
              <br />
              회사가 운영하는 사이트에서는 만14세 미만 아동의 경우 회원가입이 불가능합니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              <strong>이용자의 권리와 그 행사방법</strong>
              <br />
              이용자는 개인정보 조회, 수정을 위해서 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
              조치하겠습니다.
              <br />
              이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는
              제공하지 않습니다.
              <br />
              또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이
              이루어지도록 하겠습니다.
              <br />
              회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및
              이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</strong>
              <br />
              회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는
              '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는 데 이용되는 서버(HTTP)가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  쿠키의 사용 목적
                  <br />
                  이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 로그인 여부 등을 파악하여 이용자에게 최적화된
                  정보 제공을 위하여 사용합니다. 또한 이용자의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과
                  관심분야를 파악하여 타겟(Target) 마케팅 및 서비스 개편 등의 척도로 활용합니다.
                </p>
              </li>
              <li>
                <p>
                  쿠키의 설치운영 및 거부
                  <br />
                  이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
                  설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
                  거부할 수도 있습니다.
                  <br />
                  쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를
                  허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                  <br />
                  설정방법 (예:인터넷 익스플로어의 경우)
                  <br />
                  웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
                  <br />
                  다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수
                  있습니다.
                </p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보의 기술적/관리적 보호 대책</strong>
              <br />
              회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성
              확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
            </p>
          </div>
          <div className="olist arabic">
            <ol className="arabic">
              <li>
                <p>
                  개인정보 암호화
                  <br />
                  이용자의 주요 개인정보는 암호화하여 저장하고 있으며, 파일 및 전송데이터를 암호화하여 혹시 발생할 수
                  있는 사고 시라도 이용자의 개인정보가 유출되지 않도록 관리되고 있습니다.
                </p>
              </li>
              <li>
                <p>
                  해킹 등에 대비한 대책
                  <br />
                  회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해
                  최선을 다하고 있습니다.
                  <br />
                  개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의
                  개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서
                  개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 24시간 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적
                  장치를 갖추려 노력하고 있습니다.
                </p>
              </li>
              <li>
                <p>
                  처리 직원의 최소화 및 교육
                  <br />
                  회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여
                  정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고
                  있습니다.
                </p>
              </li>
              <li>
                <p>
                  개인정보보호전담기구의 운영
                  <br />
                  회사는 사내 개인정보보호전담기구 등을 통하여 개인정보보호방침의 이행사항 및 담당자의 준수 여부를
                  확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 하지만 근본적으로
                  개인정보의 보안관리에는 이용자 본인의 노력도 중요합니다. Online 서비스는 오직 아이디와 비밀번호를 알고
                  있는 본인에게만 개인정보에 접근할 수 있는 권한이 있으며, 비밀번호를 유지하고 관리할 책임 역시도
                  본인에게 있습니다. 반드시 본인만이 알 수 있는 내용으로 비밀번호를 구성해야 하며, 주민번호의 일부 또는
                  전화번호 등 타인이 쉽게 도용할 수 있는 비밀번호의 사용을 지양해야 합니다. 또한 공동으로 사용하는
                  PC에서 회사의 서비스에 접속하여 로그인한 상태에서 다른 사이트로 이동할 경우, 혹은 서비스 이용을
                  종료하였을 경우에는 반드시 해당 브라우저를 종료하시기 바랍니다. 그렇지 않을 경우, 해당 브라우저를 통해
                  아이디, 비밀번호, 주민등록번호가 포함된 이용자의 개인 정보가 타인에게 손쉽게 유출될 위험이 있습니다.
                  이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한
                  문제에 대해 회사는 일체의 책임을 지지 않습니다.
                </p>
              </li>
            </ol>
          </div>
          <div className="paragraph">
            <p>
              <strong>개인정보관리책임자 및 담당자의 연락처</strong>
              <br />
              회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 담당자를 지정하고 있고,
              연락처는 아래와 같습니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              개인정보 관리책임자(적용일시: 21.11.01)
              <br />
              이름 : 카몬 성백환 대표
              <br />
              전화번호 : 070-7576-4981
              <br />
              E-MAIL : <a href="mailto:carmonster000@gmail.com">carmonster000@gmail.com</a>
              <br />
              귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은
              담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
              <br />
              개인정보보호 종합지원 포털 (안전행정부 운영)
              <br />
              www.privacy.go.kr / 02-2100-3343
              <br />
              개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              privacy.kisa.or.kr / (국번없이) 118
              <br />
              개인정보보호지원센터 (한국정보화진흥원)
              <br />
              privacy.nia.or.kr / 02-2131-0111
            </p>
          </div>
          <div className="paragraph">
            <p>
              <strong>기타</strong>
              <br />
              회사가 운영하는 사이트에 링크되어 있는 (모바일 웹/앱 포함) 개인정보를 수집하는 행위에 대해서는 본 "카몬
              개인정보취급방침"이 적용되지 않음을 알려 드립니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              <strong>고지의 의무</strong>
              <br />
              현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 변경이유 및 내용을
              홈페이지의 '공지사항&#8217;을 통해 고지할 것입니다.
              <br />본 개인정보취급방침의 내용은 수시로 변경될 수 있으므로 사이트를 방문하실 때마다, 이를 확인하시기
              바랍니다.
            </p>
          </div>
          <div className="paragraph">
            <p>
              개인정보처리방침 버전번호 : ver 1.0
              <br />
              공고일자 : 2021년 11월 1일
            </p>
          </div>
        </PrivacyContainer>
      </Container>
    </>
  );
};

export default Privacy;

const PrivacyContainer = styled.div`
  /* font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 200;

  h3 {
    text-align: center;
    font-size: 3rem;
  } */
  a {
    background: none;
  }
  a:focus {
    outline: thin dotted;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  b,
  strong {
    font-weight: bold;
  }
  abbr {
    font-size: 0.9em;
  }
  abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #dddddf;
    text-decoration: none;
  }
  dfn {
    font-style: italic;
  }
  hr {
    height: 0;
  }
  mark {
    background: #ff0;
    color: #000;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace;
    font-size: 1em;
  }
  pre {
    white-space: pre-wrap;
  }
  q {
    quotes: "\\201C""\\201D""\\2018""\\2019";
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  figure {
    margin: 0;
  }
  audio,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }
  legend {
    border: 0;
    padding: 0;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }
  button,
  input {
    line-height: normal;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  input[type="checkbox"],
  input[type="radio"] {
    padding: 0;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  textarea {
    overflow: auto;
    vertical-align: top;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
  html,
  body {
    font-size: 100%;
  }
  body {
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    padding: 0;
    margin: 0;
    font-family: "Noto Serif", "DejaVu Serif", serif;
    line-height: 1;
    position: relative;
    cursor: auto;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    word-wrap: anywhere;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  a:hover {
    cursor: pointer;
  }
  img,
  object,
  embed {
    max-width: 100%;
    height: auto;
  }
  object,
  embed {
    height: 100%;
  }
  img {
    -ms-interpolation-mode: bicubic;
  }
  .left {
    float: left !important;
  }
  .right {
    float: right !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .hide {
    display: none;
  }
  img,
  object,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  textarea {
    height: auto;
    min-height: 50px;
  }
  select {
    width: 100%;
  }
  .subheader,
  .admonitionblock td.content > .title,
  .audioblock > .title,
  .exampleblock > .title,
  .imageblock > .title,
  .listingblock > .title,
  .literalblock > .title,
  .stemblock > .title,
  .openblock > .title,
  .paragraph > .title,
  .quoteblock > .title,
  table.tableblock > .title,
  .verseblock > .title,
  .videoblock > .title,
  .dlist > .title,
  .olist > .title,
  .ulist > .title,
  .qlist > .title,
  .hdlist > .title {
    line-height: 1.45;
    color: #7a2518;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.25em;
  }
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  #toctitle,
  .sidebarblock > .content > .title,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }
  a {
    color: #2156a5;
    text-decoration: underline;
    line-height: inherit;
  }
  a:hover,
  a:focus {
    color: #1d4b8f;
  }
  a img {
    border: 0;
  }
  p {
    line-height: 1.6;
    margin-bottom: 1.25em;
    text-rendering: optimizeLegibility;
  }
  p aside {
    font-size: 0.875em;
    line-height: 1.35;
    font-style: italic;
  }
  h1,
  h2,
  h3,
  #toctitle,
  .sidebarblock > .content > .title,
  h4,
  h5,
  h6 {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #ba3925;
    text-rendering: optimizeLegibility;
    margin-top: 1em;
    margin-bottom: 0.5em;
    line-height: 1.0125em;
  }
  h1 small,
  h2 small,
  h3 small,
  #toctitle small,
  .sidebarblock > .content > .title small,
  h4 small,
  h5 small,
  h6 small {
    font-size: 60%;
    color: #e99b8f;
    line-height: 0;
  }
  h1 {
    font-size: 2.125em;
  }
  h2 {
    font-size: 1.6875em;
  }
  h3,
  #toctitle,
  .sidebarblock > .content > .title {
    font-size: 1.375em;
  }
  h4,
  h5 {
    font-size: 1.125em;
  }
  h6 {
    font-size: 1em;
  }
  hr {
    border: solid #dddddf;
    border-width: 1px 0 0;
    clear: both;
    margin: 1.25em 0 1.1875em;
  }
  em,
  i {
    font-style: italic;
    line-height: inherit;
  }
  strong,
  b {
    font-weight: bold;
    line-height: inherit;
  }
  small {
    font-size: 60%;
    line-height: inherit;
  }
  code {
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }
  ul,
  ol,
  dl {
    line-height: 1.6;
    margin-bottom: 1.25em;
    list-style-position: outside;
    font-family: inherit;
  }
  ul,
  ol {
    margin-left: 1.5em;
  }
  ul li ul,
  ul li ol {
    margin-left: 1.25em;
    margin-bottom: 0;
  }
  ul.square li ul,
  ul.circle li ul,
  ul.disc li ul {
    list-style: inherit;
  }
  ul.square {
    list-style-type: square;
  }
  ul.circle {
    list-style-type: circle;
  }
  ul.disc {
    list-style-type: disc;
  }
  ol li ul,
  ol li ol {
    margin-left: 1.25em;
    margin-bottom: 0;
  }
  dl dt {
    margin-bottom: 0.3125em;
    font-weight: bold;
  }
  dl dd {
    margin-bottom: 1.25em;
  }
  blockquote {
    margin: 0 0 1.25em;
    padding: 0.5625em 1.25em 0 1.1875em;
    border-left: 1px solid #ddd;
  }
  blockquote,
  blockquote p {
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.85);
  }
  @media screen and (min-width: 768px) {
    h1,
    h2,
    h3,
    #toctitle,
    .sidebarblock > .content > .title,
    h4,
    h5,
    h6 {
      line-height: 1.2;
    }
    h1 {
      font-size: 2.75em;
    }
    h2 {
      font-size: 2.3125em;
    }
    h3,
    #toctitle,
    .sidebarblock > .content > .title {
      font-size: 1.6875em;
    }
    h4 {
      font-size: 1.4375em;
    }
  }
  table {
    background: #fff;
    margin-bottom: 1.25em;
    border: 1px solid #dedede;
    word-wrap: normal;
  }
  table thead,
  table tfoot {
    background: #f7f8f7;
  }
  table thead tr th,
  table thead tr td,
  table tfoot tr th,
  table tfoot tr td {
    padding: 0.5em 0.625em 0.625em;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
  }
  table tr th,
  table tr td {
    padding: 0.5625em 0.625em;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.8);
  }
  table tr.even,
  table tr.alt {
    background: #f8f8f7;
  }
  table thead tr th,
  table tfoot tr th,
  table tbody tr td,
  table tr td,
  table tfoot tr td {
    line-height: 1.6;
  }
  h1,
  h2,
  h3,
  #toctitle,
  .sidebarblock > .content > .title,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    word-spacing: -0.05em;
  }
  h1 strong,
  h2 strong,
  h3 strong,
  #toctitle strong,
  .sidebarblock > .content > .title strong,
  h4 strong,
  h5 strong,
  h6 strong {
    font-weight: 400;
  }
  .center {
    margin-left: auto;
    margin-right: auto;
  }
  .stretch {
    width: 100%;
  }
  .clearfix::before,
  .clearfix::after,
  .float-group::before,
  .float-group::after {
    content: " ";
    display: table;
  }
  .clearfix::after,
  .float-group::after {
    clear: both;
  }
  :not(pre).nobreak {
    word-wrap: normal;
  }
  :not(pre).nowrap {
    white-space: nowrap;
  }
  :not(pre).pre-wrap {
    white-space: pre-wrap;
  }
  :not(pre):not([className^="L"]) > code {
    font-size: 0.9375em;
    font-style: normal !important;
    letter-spacing: 0;
    padding: 0.1em 0.5ex;
    word-spacing: -0.15em;
    background: #f7f7f8;
    border-radius: 4px;
    line-height: 1.45;
    text-rendering: optimizeSpeed;
  }
  pre {
    color: rgba(0, 0, 0, 0.9);
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    line-height: 1.45;
    text-rendering: optimizeSpeed;
  }
  pre code,
  pre pre {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  pre > code {
    display: block;
  }
  pre.nowrap,
  pre.nowrap pre {
    white-space: pre;
    word-wrap: normal;
  }
  em em {
    font-style: normal;
  }
  strong strong {
    font-weight: 400;
  }
  .keyseq {
    color: rgba(51, 51, 51, 0.8);
  }
  kbd {
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    display: inline-block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.65em;
    line-height: 1.45;
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 0.1em #fff;
    margin: 0 0.15em;
    padding: 0.2em 0.5em;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    white-space: nowrap;
  }
  .keyseq kbd:first-child {
    margin-left: 0;
  }
  .keyseq kbd:last-child {
    margin-right: 0;
  }
  .menuseq,
  .menuref {
    color: #000;
  }
  .menuseq b:not(.caret),
  .menuref {
    font-weight: inherit;
  }
  .menuseq {
    word-spacing: -0.02em;
  }
  .menuseq b.caret {
    font-size: 1.25em;
    line-height: 0.8;
  }
  .menuseq i.caret {
    font-weight: bold;
    text-align: center;
    width: 0.45em;
  }
  b.button::before,
  b.button::after {
    position: relative;
    top: -1px;
    font-weight: 400;
  }
  b.button::before {
    content: "[";
    padding: 0 3px 0 2px;
  }
  b.button::after {
    content: "]";
    padding: 0 2px 0 3px;
  }
  p a > code:hover {
    color: rgba(0, 0, 0, 0.9);
  }

  #content h1 > a.anchor,
  h2 > a.anchor,
  h3 > a.anchor,
  #toctitle > a.anchor,
  .sidebarblock > .content > .title > a.anchor,
  h4 > a.anchor,
  h5 > a.anchor,
  h6 > a.anchor {
    position: absolute;
    z-index: 1001;
    width: 1.5ex;
    margin-left: -1.5ex;
    display: block;
    text-decoration: none !important;
    visibility: hidden;
    text-align: center;
    font-weight: 400;
  }
  #content h1 > a.anchor::before,
  h2 > a.anchor::before,
  h3 > a.anchor::before,
  #toctitle > a.anchor::before,
  .sidebarblock > .content > .title > a.anchor::before,
  h4 > a.anchor::before,
  h5 > a.anchor::before,
  h6 > a.anchor::before {
    content: "\\00A7";
    font-size: 0.85em;
    display: block;
    padding-top: 0.1em;
  }
  #content h1:hover > a.anchor,
  #content h1 > a.anchor:hover,
  h2:hover > a.anchor,
  h2 > a.anchor:hover,
  h3:hover > a.anchor,
  #toctitle:hover > a.anchor,
  .sidebarblock > .content > .title:hover > a.anchor,
  h3 > a.anchor:hover,
  #toctitle > a.anchor:hover,
  .sidebarblock > .content > .title > a.anchor:hover,
  h4:hover > a.anchor,
  h4 > a.anchor:hover,
  h5:hover > a.anchor,
  h5 > a.anchor:hover,
  h6:hover > a.anchor,
  h6 > a.anchor:hover {
    visibility: visible;
  }
  #content h1 > a.link,
  h2 > a.link,
  h3 > a.link,
  #toctitle > a.link,
  .sidebarblock > .content > .title > a.link,
  h4 > a.link,
  h5 > a.link,
  h6 > a.link {
    color: #ba3925;
    text-decoration: none;
  }
  #content h1 > a.link:hover,
  h2 > a.link:hover,
  h3 > a.link:hover,
  #toctitle > a.link:hover,
  .sidebarblock > .content > .title > a.link:hover,
  h4 > a.link:hover,
  h5 > a.link:hover,
  h6 > a.link:hover {
    color: #a53221;
  }
  details,
  .audioblock,
  .imageblock,
  .literalblock,
  .listingblock,
  .stemblock,
  .videoblock {
    margin-bottom: 1.25em;
  }
  details {
    margin-left: 1.25rem;
  }
  details > summary {
    cursor: pointer;
    display: block;
    position: relative;
    line-height: 1.6;
    margin-bottom: 0.625rem;
    -webkit-tap-highlight-color: transparent;
  }
  details > summary::before {
    content: "";
    border: solid transparent;
    border-left: solid;
    border-width: 0.3em 0 0.3em 0.5em;
    position: absolute;
    top: 0.5em;
    left: -1.25rem;
    transform: translateX(15%);
  }
  details[open] > summary::before {
    border: solid transparent;
    border-top: solid;
    border-width: 0.5em 0.3em 0;
    transform: translateY(15%);
  }
  details > summary::after {
    content: "";
    width: 1.25rem;
    height: 1em;
    position: absolute;
    top: 0.3em;
    left: -1.25rem;
  }
  .admonitionblock td.content > .title,
  .audioblock > .title,
  .exampleblock > .title,
  .imageblock > .title,
  .listingblock > .title,
  .literalblock > .title,
  .stemblock > .title,
  .openblock > .title,
  .paragraph > .title,
  .quoteblock > .title,
  table.tableblock > .title,
  .verseblock > .title,
  .videoblock > .title,
  .dlist > .title,
  .olist > .title,
  .ulist > .title,
  .qlist > .title,
  .hdlist > .title {
    text-rendering: optimizeLegibility;
    text-align: left;
    font-family: "Noto Serif", "DejaVu Serif", serif;
    font-size: 1rem;
    font-style: italic;
  }
  table.tableblock.fit-content > caption.title {
    white-space: nowrap;
    width: 0;
  }
  .paragraph.lead > p,
  #preamble > .sectionbody > [className="paragraph"]:first-of-type p {
    font-size: 1.21875em;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.85);
  }
  .admonitionblock > table {
    border-collapse: separate;
    border: 0;
    background: none;
    width: 100%;
  }
  .admonitionblock > table td.icon {
    text-align: center;
    width: 80px;
  }
  .admonitionblock > table td.icon img {
    max-width: none;
  }
  .admonitionblock > table td.icon .title {
    font-weight: bold;
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    text-transform: uppercase;
  }
  .admonitionblock > table td.content {
    padding-left: 1.125em;
    padding-right: 1.25em;
    border-left: 1px solid #dddddf;
    color: rgba(0, 0, 0, 0.6);
    word-wrap: anywhere;
  }
  .admonitionblock > table td.content > :last-child > :last-child {
    margin-bottom: 0;
  }
  .exampleblock > .content {
    border: 1px solid #e6e6e6;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: #fff;
    border-radius: 4px;
  }
  .exampleblock > .content > :first-child {
    margin-top: 0;
  }
  .exampleblock > .content > :last-child {
    margin-bottom: 0;
  }
  .sidebarblock {
    border: 1px solid #dbdbd6;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: #f3f3f2;
    border-radius: 4px;
  }
  .sidebarblock > :first-child {
    margin-top: 0;
  }
  .sidebarblock > :last-child {
    margin-bottom: 0;
  }
  .sidebarblock > .content > .title {
    color: #7a2518;
    margin-top: 0;
    text-align: center;
  }
  .exampleblock > .content > :last-child > :last-child,
  .exampleblock > .content .olist > ol > li:last-child > :last-child,
  .exampleblock > .content .ulist > ul > li:last-child > :last-child,
  .exampleblock > .content .qlist > ol > li:last-child > :last-child,
  .sidebarblock > .content > :last-child > :last-child,
  .sidebarblock > .content .olist > ol > li:last-child > :last-child,
  .sidebarblock > .content .ulist > ul > li:last-child > :last-child,
  .sidebarblock > .content .qlist > ol > li:last-child > :last-child {
    margin-bottom: 0;
  }
  .literalblock pre,
  .listingblock > .content > pre {
    border-radius: 4px;
    overflow-x: auto;
    padding: 1em;
    font-size: 0.8125em;
  }
  @media screen and (min-width: 768px) {
    .literalblock pre,
    .listingblock > .content > pre {
      font-size: 0.90625em;
    }
  }
  @media screen and (min-width: 1280px) {
    .literalblock pre,
    .listingblock > .content > pre {
      font-size: 1em;
    }
  }
  .literalblock pre,
  .listingblock > .content > pre:not(.highlight),
  .listingblock > .content > pre[className="highlight"],
  .listingblock > .content > pre[className^="highlight "] {
    background: #f7f7f8;
  }
  .literalblock.output pre {
    color: #f7f7f8;
    background: rgba(0, 0, 0, 0.9);
  }
  .listingblock > .content {
    position: relative;
  }
  .listingblock code[data-lang]::before {
    display: none;
    content: attr(data-lang);
    position: absolute;
    font-size: 0.75em;
    top: 0.425rem;
    right: 0.5rem;
    line-height: 1;
    text-transform: uppercase;
    color: inherit;
    opacity: 0.5;
  }
  .listingblock:hover code[data-lang]::before {
    display: block;
  }
  .listingblock.terminal pre .command::before {
    content: attr(data-prompt);
    padding-right: 0.5em;
    color: inherit;
    opacity: 0.5;
  }
  .listingblock.terminal pre .command:not([data-prompt])::before {
    content: "$";
  }
  .listingblock pre.highlightjs {
    padding: 0;
  }
  .listingblock pre.highlightjs > code {
    padding: 1em;
    border-radius: 4px;
  }
  .listingblock pre.prettyprint {
    border-width: 0;
  }
  .prettyprint {
    background: #f7f7f8;
  }
  pre.prettyprint .linenums {
    line-height: 1.45;
    margin-left: 2em;
  }
  pre.prettyprint li {
    background: none;
    list-style-type: inherit;
    padding-left: 0;
  }
  pre.prettyprint li code[data-lang]::before {
    opacity: 1;
  }
  pre.prettyprint li:not(:first-child) code[data-lang]::before {
    display: none;
  }
  table.linenotable {
    border-collapse: separate;
    border: 0;
    margin-bottom: 0;
    background: none;
  }
  table.linenotable td[className] {
    color: inherit;
    vertical-align: top;
    padding: 0;
    line-height: inherit;
    white-space: normal;
  }
  table.linenotable td.code {
    padding-left: 0.75em;
  }
  table.linenotable td.linenos {
    border-right: 1px solid;
    opacity: 0.35;
    padding-right: 0.5em;
  }
  pre.pygments .lineno {
    border-right: 1px solid;
    opacity: 0.35;
    display: inline-block;
    margin-right: 0.75em;
  }
  pre.pygments .lineno::before {
    content: "";
    margin-right: -0.125em;
  }
  .quoteblock {
    margin: 0 1em 1.25em 1.5em;
    display: table;
  }
  .quoteblock:not(.excerpt) > .title {
    margin-left: -1.5em;
    margin-bottom: 0.75em;
  }
  .quoteblock blockquote,
  .quoteblock p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.15rem;
    line-height: 1.75;
    word-spacing: 0.1em;
    letter-spacing: 0;
    font-style: italic;
    text-align: justify;
  }
  .quoteblock blockquote {
    margin: 0;
    padding: 0;
    border: 0;
  }
  .quoteblock blockquote::before {
    content: "\\201c";
    float: left;
    font-size: 2.75em;
    font-weight: bold;
    line-height: 0.6em;
    margin-left: -0.6em;
    color: #7a2518;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  .quoteblock blockquote > .paragraph:last-child p {
    margin-bottom: 0;
  }
  .quoteblock .attribution {
    margin-top: 0.75em;
    margin-right: 0.5ex;
    text-align: right;
  }
  .verseblock {
    margin: 0 1em 1.25em;
  }
  .verseblock pre {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-size: 1.15rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 300;
    text-rendering: optimizeLegibility;
  }
  .verseblock pre strong {
    font-weight: 400;
  }
  .verseblock .attribution {
    margin-top: 1.25rem;
    margin-left: 0.5ex;
  }
  .quoteblock .attribution,
  .verseblock .attribution {
    font-size: 0.9375em;
    line-height: 1.45;
    font-style: italic;
  }
  .quoteblock .attribution br,
  .verseblock .attribution br {
    display: none;
  }
  .quoteblock .attribution cite,
  .verseblock .attribution cite {
    display: block;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.6);
  }
  .quoteblock.abstract blockquote::before,
  .quoteblock.excerpt blockquote::before,
  .quoteblock .quoteblock blockquote::before {
    display: none;
  }
  .quoteblock.abstract blockquote,
  .quoteblock.abstract p,
  .quoteblock.excerpt blockquote,
  .quoteblock.excerpt p,
  .quoteblock .quoteblock blockquote,
  .quoteblock .quoteblock p {
    line-height: 1.6;
    word-spacing: 0;
  }
  .quoteblock.abstract {
    margin: 0 1em 1.25em;
    display: block;
  }
  .quoteblock.abstract > .title {
    margin: 0 0 0.375em;
    font-size: 1.15em;
    text-align: center;
  }
  .quoteblock.excerpt > blockquote,
  .quoteblock .quoteblock {
    padding: 0 0 0.25em 1em;
    border-left: 0.25em solid #dddddf;
  }
  .quoteblock.excerpt,
  .quoteblock .quoteblock {
    margin-left: 0;
  }
  .quoteblock.excerpt blockquote,
  .quoteblock.excerpt p,
  .quoteblock .quoteblock blockquote,
  .quoteblock .quoteblock p {
    color: inherit;
    font-size: 1.0625rem;
  }
  .quoteblock.excerpt .attribution,
  .quoteblock .quoteblock .attribution {
    color: inherit;
    font-size: 0.85rem;
    text-align: left;
    margin-right: 0;
  }
  p.tableblock:last-child {
    margin-bottom: 0;
  }
  td.tableblock > .content {
    margin-bottom: 1.25em;
    word-wrap: anywhere;
  }
  td.tableblock > .content > :last-child {
    margin-bottom: -1.25em;
  }
  table.tableblock,
  th.tableblock,
  td.tableblock {
    border: 0 solid #dedede;
  }
  table.grid-all > * > tr > * {
    border-width: 1px;
  }
  table.grid-cols > * > tr > * {
    border-width: 0 1px;
  }
  table.grid-rows > * > tr > * {
    border-width: 1px 0;
  }
  table.frame-all {
    border-width: 1px;
  }
  table.frame-ends {
    border-width: 1px 0;
  }
  table.frame-sides {
    border-width: 0 1px;
  }
  table.frame-none > colgroup + * > :first-child > *,
  table.frame-sides > colgroup + * > :first-child > * {
    border-top-width: 0;
  }
  table.frame-none > :last-child > :last-child > *,
  table.frame-sides > :last-child > :last-child > * {
    border-bottom-width: 0;
  }
  table.frame-none > * > tr > :first-child,
  table.frame-ends > * > tr > :first-child {
    border-left-width: 0;
  }
  table.frame-none > * > tr > :last-child,
  table.frame-ends > * > tr > :last-child {
    border-right-width: 0;
  }
  table.stripes-all tr,
  table.stripes-odd tr:nth-of-type(odd),
  table.stripes-even tr:nth-of-type(even),
  table.stripes-hover tr:hover {
    background: #f8f8f7;
  }
  th.halign-left,
  td.halign-left {
    text-align: left;
  }
  th.halign-right,
  td.halign-right {
    text-align: right;
  }
  th.halign-center,
  td.halign-center {
    text-align: center;
  }
  th.valign-top,
  td.valign-top {
    vertical-align: top;
  }
  th.valign-bottom,
  td.valign-bottom {
    vertical-align: bottom;
  }
  th.valign-middle,
  td.valign-middle {
    vertical-align: middle;
  }
  table thead th,
  table tfoot th {
    font-weight: bold;
  }
  tbody tr th {
    background: #f7f8f7;
  }
  tbody tr th,
  tbody tr th p,
  tfoot tr th,
  tfoot tr th p {
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
  }
  p.tableblock > code:only-child {
    background: none;
    padding: 0;
  }
  p.tableblock {
    font-size: 1em;
  }
  ol {
    margin-left: 1.75em;
  }
  ul li ol {
    margin-left: 1.5em;
  }
  dl dd {
    margin-left: 1.125em;
  }
  dl dd:last-child,
  dl dd:last-child > :last-child {
    margin-bottom: 0;
  }
  ol > li p,
  ul > li p,
  ul dd,
  ol dd,
  .olist .olist,
  .ulist .ulist,
  .ulist .olist,
  .olist .ulist {
    margin-bottom: 0.625em;
  }
  ul.checklist,
  ul.none,
  ol.none,
  ul.no-bullet,
  ol.no-bullet,
  ol.unnumbered,
  ul.unstyled,
  ol.unstyled {
    list-style-type: none;
  }
  ul.no-bullet,
  ol.no-bullet,
  ol.unnumbered {
    margin-left: 0.625em;
  }
  ul.unstyled,
  ol.unstyled {
    margin-left: 0;
  }
  ul.checklist > li > p:first-child {
    margin-left: -1em;
  }
  ul.checklist > li > p:first-child > .fa-square-o:first-child,
  ul.checklist > li > p:first-child > .fa-check-square-o:first-child {
    width: 1.25em;
    font-size: 0.8em;
    position: relative;
    bottom: 0.125em;
  }
  ul.checklist > li > p:first-child > input[type="checkbox"]:first-child {
    margin-right: 0.25em;
  }
  ul.inline {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0 0 0.625em -1.25em;
  }
  ul.inline > li {
    margin-left: 1.25em;
  }
  .unstyled dl dt {
    font-weight: 400;
    font-style: normal;
  }
  ol.arabic {
    list-style-type: decimal;
  }
  ol.decimal {
    list-style-type: decimal-leading-zero;
  }
  ol.loweralpha {
    list-style-type: lower-alpha;
  }
  ol.upperalpha {
    list-style-type: upper-alpha;
  }
  ol.lowerroman {
    list-style-type: lower-roman;
  }
  ol.upperroman {
    list-style-type: upper-roman;
  }
  ol.lowergreek {
    list-style-type: lower-greek;
  }
  .hdlist > table,
  .colist > table {
    border: 0;
    background: none;
  }
  .hdlist > table > tbody > tr,
  .colist > table > tbody > tr {
    background: none;
  }
  td.hdlist1,
  td.hdlist2 {
    vertical-align: top;
    padding: 0 0.625em;
  }
  td.hdlist1 {
    font-weight: bold;
    padding-bottom: 1.25em;
  }
  td.hdlist2 {
    word-wrap: anywhere;
  }
  .literalblock + .colist,
  .listingblock + .colist {
    margin-top: -0.5em;
  }
  .colist td:not([className]):first-child {
    padding: 0.4em 0.75em 0;
    line-height: 1;
    vertical-align: top;
  }
  .colist td:not([className]):first-child img {
    max-width: none;
  }
  .colist td:not([className]):last-child {
    padding: 0.25em 0;
  }
  .thumb,
  .th {
    line-height: 0;
    display: inline-block;
    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #ddd;
  }
  .imageblock.left {
    margin: 0.25em 0.625em 1.25em 0;
  }
  .imageblock.right {
    margin: 0.25em 0 1.25em 0.625em;
  }
  .imageblock > .title {
    margin-bottom: 0;
  }
  .imageblock.thumb,
  .imageblock.th {
    border-width: 6px;
  }
  .imageblock.thumb > .title,
  .imageblock.th > .title {
    padding: 0 0.125em;
  }
  .image.left,
  .image.right {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    display: inline-block;
    line-height: 0;
  }
  .image.left {
    margin-right: 0.625em;
  }
  .image.right {
    margin-left: 0.625em;
  }
  a.image {
    text-decoration: none;
    display: inline-block;
  }
  a.image object {
    pointer-events: none;
  }
  sup.footnote,
  sup.footnoteref {
    font-size: 0.875em;
    position: static;
    vertical-align: super;
  }
  sup.footnote a,
  sup.footnoteref a {
    text-decoration: none;
  }
  sup.footnote a:active,
  sup.footnoteref a:active {
    text-decoration: underline;
  }
  #footnotes {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    margin-bottom: 0.625em;
  }
  #footnotes hr {
    width: 20%;
    min-width: 6.25em;
    margin: -0.25em 0 0.75em;
    border-width: 1px 0 0;
  }
  #footnotes .footnote {
    padding: 0 0.375em 0 0.225em;
    line-height: 1.3334;
    font-size: 0.875em;
    margin-left: 1.2em;
    margin-bottom: 0.2em;
  }
  #footnotes .footnote a:first-of-type {
    font-weight: bold;
    text-decoration: none;
    margin-left: -1.05em;
  }
  #footnotes .footnote:last-of-type {
    margin-bottom: 0;
  }
  #content #footnotes {
    margin-top: -0.625em;
    margin-bottom: 0;
    padding: 0.75em 0;
  }
  .gist .file-data > table {
    border: 0;
    background: #fff;
    width: 100%;
    margin-bottom: 0;
  }
  .gist .file-data > table td.line-data {
    width: 99%;
  }
  div.unbreakable {
    page-break-inside: avoid;
  }
  .big {
    font-size: larger;
  }
  .small {
    font-size: smaller;
  }
  .underline {
    text-decoration: underline;
  }
  .overline {
    text-decoration: overline;
  }
  .line-through {
    text-decoration: line-through;
  }
  .aqua {
    color: #00bfbf;
  }
  .aqua-background {
    background: #00fafa;
  }
  .black {
    color: #000;
  }
  .black-background {
    background: #000;
  }
  .blue {
    color: #0000bf;
  }
  .blue-background {
    background: #0000fa;
  }
  .fuchsia {
    color: #bf00bf;
  }
  .fuchsia-background {
    background: #fa00fa;
  }
  .gray {
    color: #606060;
  }
  .gray-background {
    background: #7d7d7d;
  }
  .green {
    color: #006000;
  }
  .green-background {
    background: #007d00;
  }
  .lime {
    color: #00bf00;
  }
  .lime-background {
    background: #00fa00;
  }
  .maroon {
    color: #600000;
  }
  .maroon-background {
    background: #7d0000;
  }
  .navy {
    color: #000060;
  }
  .navy-background {
    background: #00007d;
  }
  .olive {
    color: #606000;
  }
  .olive-background {
    background: #7d7d00;
  }
  .purple {
    color: #600060;
  }
  .purple-background {
    background: #7d007d;
  }
  .red {
    color: #bf0000;
  }
  .red-background {
    background: #fa0000;
  }
  .silver {
    color: #909090;
  }
  .silver-background {
    background: #bcbcbc;
  }
  .teal {
    color: #006060;
  }
  .teal-background {
    background: #007d7d;
  }
  .white {
    color: #bfbfbf;
  }
  .white-background {
    background: #fafafa;
  }
  .yellow {
    color: #bfbf00;
  }
  .yellow-background {
    background: #fafa00;
  }
  span.icon > .fa {
    cursor: default;
  }
  a span.icon > .fa {
    cursor: inherit;
  }
  .admonitionblock td.icon [className^="fa icon-"] {
    font-size: 2.5em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    cursor: default;
  }
  .admonitionblock td.icon .icon-note::before {
    content: "\\f05a";
    color: #19407c;
  }
  .admonitionblock td.icon .icon-tip::before {
    content: "\\f0eb";
    text-shadow: 1px 1px 2px rgba(155, 155, 0, 0.8);
    color: #111;
  }
  .admonitionblock td.icon .icon-warning::before {
    content: "\\f071";
    color: #bf6900;
  }
  .admonitionblock td.icon .icon-caution::before {
    content: "\\f06d";
    color: #bf3400;
  }
  .admonitionblock td.icon .icon-important::before {
    content: "\\f06a";
    color: #bf0000;
  }
  .conum[data-value] {
    display: inline-block;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    text-align: center;
    font-size: 0.75em;
    width: 1.67em;
    height: 1.67em;
    line-height: 1.67em;
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
  }
  .conum[data-value] * {
    color: #fff !important;
  }
  .conum[data-value] + b {
    display: none;
  }
  .conum[data-value]::after {
    content: attr(data-value);
  }
  pre .conum[data-value] {
    position: relative;
    top: -0.125em;
  }
  b.conum * {
    color: inherit !important;
  }
  .conum:not([data-value]):empty {
    display: none;
  }
  dt,
  th.tableblock,
  td.content,
  div.footnote {
    text-rendering: optimizeLegibility;
  }
  h1,
  h2,
  p,
  td.content,
  span.alt,
  summary {
    letter-spacing: -0.01em;
  }
  p strong,
  td.content strong,
  div.footnote strong {
    letter-spacing: -0.005em;
  }
  p,
  blockquote,
  dt,
  td.content,
  span.alt,
  summary {
    font-size: 1.0625rem;
  }
  p {
    margin-bottom: 1.25rem;
  }
  .sidebarblock p,
  .sidebarblock dt,
  .sidebarblock td.content,
  p.tableblock {
    font-size: 1em;
  }
  .exampleblock > .content {
    background: #fffef7;
    border-color: #e0e0dc;
    box-shadow: 0 1px 4px #e0e0dc;
  }
  .print-only {
    display: none !important;
  }
  @page {
    margin: 1.25cm 0.75cm;
  }
  @media print {
    * {
      box-shadow: none !important;
      text-shadow: none !important;
    }
    html {
      font-size: 80%;
    }
    a {
      color: inherit !important;
      text-decoration: underline !important;
    }
    a.bare,
    a[href^="#"],
    a[href^="mailto:"] {
      text-decoration: none !important;
    }
    a[href^="http:"]:not(.bare)::after,
    a[href^="https:"]:not(.bare)::after {
      content: "(" attr(href) ")";
      display: inline-block;
      font-size: 0.875em;
      padding-left: 0.25em;
    }
    abbr[title] {
      border-bottom: 1px dotted;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre,
    blockquote,
    tr,
    img,
    object,
    svg {
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    svg {
      max-width: 100%;
    }
    p,
    blockquote,
    dt,
    td.content {
      font-size: 1em;
      orphans: 3;
      widows: 3;
    }
    h2,
    h3,
    #toctitle,
    .sidebarblock > .content > .title {
      page-break-after: avoid;
    }

    .sidebarblock,
    .exampleblock > .content {
      background: none !important;
    }
    #toc {
      border-bottom: 1px solid #dddddf !important;
      padding-bottom: 0 !important;
    }
    body.book #header {
      text-align: center;
    }
    body.book #header > h1:first-child {
      border: 0 !important;
      margin: 2.5em 0 1em;
    }
    body.book #header .details {
      border: 0 !important;
      display: block;
      padding: 0 !important;
    }
    body.book #header .details span:first-child {
      margin-left: 0 !important;
    }
    body.book #header .details br {
      display: block;
    }
    body.book #header .details br + span::before {
      content: none !important;
    }
    body.book #toc {
      border: 0 !important;
      text-align: left !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    body.book #toc,
    body.book #preamble,
    body.book h1.sect0,
    body.book .sect1 > h2 {
      page-break-before: always;
    }
    .listingblock code[data-lang]::before {
      display: block;
    }
    #footer {
      padding: 0 0.9375em;
    }
    .hide-on-print {
      display: none !important;
    }
    .print-only {
      display: block !important;
    }
    .hide-for-print {
      display: none !important;
    }
    .show-for-print {
      display: inherit !important;
    }
  }
  @media amzn-kf8, print {
    #header > h1:first-child {
      margin-top: 1.25rem;
    }
    .sect1 {
      padding: 0 !important;
    }
    .sect1 + .sect1 {
      border: 0;
    }
    #footer {
      background: none;
    }
    #footer-text {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.9em;
    }
  }
  @media amzn-kf8 {
    #header,
    #content,
    #footnotes,
    #footer {
      padding: 0;
    }
  }
`;
