import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import "@/styles/index.css";
import reportWebVitals from "./reportWebVitals";
import "@/styles/reset.css";
import { ChakraProvider } from "@chakra-ui/react";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))y
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
