import React from "react";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { TIMELINE } from "@/Consts";

const TimelineSection = styled.section`
  width: 100%;
  padding: 20px 0 100px 0;
  line-height: 1.2;
  text-align: left;
`;

const Container = styled.div`
  position: relative;

  &::before {
    content: "";
    background: var(--light-primary);
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    &::before {
      left: 0px;
    }
  }
`;

const TimelineItem = styled.div`
  width: 100%;
  margin-bottom: 70px;
  display: flex;
  flex-direction: ${({ $isEven }) => ($isEven ? "row-reverse" : "row")};

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const TimelineImg = styled.div`
  width: 30px;
  height: 30px;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;

  @media screen and (max-width: 768px) {
    left: 0px;
  }
`;

const TimelineContent = styled(motion.div)`
  position: relative;
  width: 45%;
  padding: 30px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);

  ${({ $isEven }) =>
    $isEven
      ? css`
          &::after {
            content: "";
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 30px;
            left: -15px;
            border-width: 10px 15px 10px 0;
            border-color: transparent #f5f5f5 transparent transparent;
          }
        `
      : css`
          &::after {
            content: "";
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 30px;
            right: -15px;
            border-width: 10px 0 10px 15px;
            border-color: transparent transparent transparent #f5f5f5;
          }
        `}

  @media screen and (max-width: 768px) {
    width: calc(100% - 30px);
    margin-left: 40px;

    &::after {
      content: "";
      position: absolute;
      border-style: solid;
      width: 0;
      height: 0;
      top: 30px;
      left: -15px;
      border-width: 10px 15px 10px 0;
      border-color: transparent #f5f5f5 transparent transparent;
    }
  }
`;

const Date = styled.p`
  font-size: 1rem;
  padding-bottom: 10px;
  color: var(--red-color);
`;

const Title = styled.h5`
  font-size: 1.5rem;
  padding-bottom: 20px;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
    padding-bottom: 15px;
  }
`;

const DetailItem = styled.li`
  font-size: 1.2rem;
  font-weight: 400 !important;
  padding-bottom: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    padding-bottom: 8px;
  }
`;

const Timeline = () => {
  return (
    <TimelineSection>
      <Container>
        {TIMELINE.map((item, index) => (
          <AnimatedTimelineItem
            key={index}
            date={item.date}
            title={item.title}
            details={item.details}
            isEven={index % 2 === 1}
          />
        ))}
      </Container>
    </TimelineSection>
  );
};

const AnimatedTimelineItem = ({ date, title, details, isEven }) => {
  return (
    <TimelineItem $isEven={isEven}>
      <TimelineImg />
      <TimelineContent
        transition={{ duration: 0.8 }}
        initial={{ opacity: 0, x: isEven ? 300 : -300 }}
        whileInView={{ opacity: 1, x: 0 }}
        $isEven={isEven}
      >
        <Date>{date}</Date>
        <Title>{title}</Title>
        <ul>
          {details.map((detail, idx) => (
            <DetailItem key={idx}>{detail}</DetailItem>
          ))}
        </ul>
      </TimelineContent>
    </TimelineItem>
  );
};

export default Timeline;
