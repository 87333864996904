import { motion } from "framer-motion";
import styled from "styled-components";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5, // 자식 요소들 사이에 0.5초 간격을 둡니다.
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  height: 350px;
  border-radius: 10px;
  background-image: url("./images/main/new/main_imgslide_2.svg");

  /* 텍스트 배치 */
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
`;

const Title = styled(motion.h1)`
  color: ${(props) => props.$color || "white"} !important;
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  text-align: center;

  padding: 0.5rem 0 0.2rem 0;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem !important;
  }
`;

const SubTitle = styled(motion.h2)`
  color: ${(props) => props.$color || "white"} !important;
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem !important;
  }
`;

export const PageHeader = ({ title, subtitle }) => {
  return (
    <Container>
      <Wrapper>
        <motion.div variants={containerVariants} initial="hidden" animate="visible">
          <Title variants={textVariants}> {title}</Title>
          <SubTitle variants={textVariants}> {subtitle}</SubTitle>
        </motion.div>
      </Wrapper>
    </Container>
  );
};
