import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: white;
  z-index: 100000;
`;

const DesktopMenu = styled.nav`
  display: flex;
  justify-content: center;

  & > div {
    flex: 1;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 100px;
  }

  @media (max-width: 600px) {
    display: none;
  }

  img {
    width: 7rem;
  }

  ul {
    display: flex;
    justify-content: flex-end;

    li {
      margin-left: 2rem;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      font-size: 1.2rem;

      &:hover {
        color: var(--red-color);
      }
    }
  }
`;

const FullscreenMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100000;
`;

const MobileMenuNavFixed = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 100000;
  padding: 0 2rem;
  height: 60px;

  img {
    height: 25px;
  }
`;

const MobileMenuNav = styled.nav`
  padding: 1rem 0;

  ul {
    li {
      padding: 1.2rem 2rem;
      cursor: pointer;

      font-size: 1.3rem;

      &:hover {
        color: var(--red-color);
        background-color: #f8f9fb;
      }
    }
  }
`;

const MenuButton = styled.p`
  font-size: 2rem;
  padding: 0 0.3rem;
  cursor: pointer;
`;

const HamburgerButton = styled(MenuButton)`
  @media (min-width: 601px) {
    display: none;
  }
`;

function CustomHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);

  const menuItems = [
    { name: "서비스", link: "/aboutService" },
    { name: "회사소개", link: "/aboutCompany" },
    { name: "뉴스", link: "/issue" },
    { name: "문의", link: "community" },
  ];

  return (
    <Container>
      <div>
        <Header>
          {isMobile ? (
            <MobileMenuNavFixed>
              <a href="/">
                <img alt="carmon logo" src="./images/logo/carmon-red.png" />
              </a>
              <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>☰</HamburgerButton>
            </MobileMenuNavFixed>
          ) : (
            <DesktopMenu>
              <div>
                <a href="/">
                  <img alt="carmon logo" src="./images/logo/carmon-red.png" />
                </a>
                <ul>
                  {menuItems.map((item, index) => (
                    <a href={item.link} key={index}>
                      <li>{item.name}</li>
                    </a>
                  ))}
                </ul>
              </div>
            </DesktopMenu>
          )}
        </Header>
        {isMobile && isMenuOpen && (
          <FullscreenMenu>
            <MobileMenuNavFixed>
              <a href="/">
                <img alt="carmon logo" src="./images/logo/carmon-red.png" />
              </a>
              <MenuButton onClick={() => setIsMenuOpen(false)}>✕</MenuButton>
            </MobileMenuNavFixed>
            <MobileMenuNav>
              <ul>
                {menuItems.map((item, index) => (
                  <a href={item.link} key={index}>
                    <li>{item.name}</li>
                  </a>
                ))}
              </ul>
            </MobileMenuNav>
          </FullscreenMenu>
        )}
      </div>
    </Container>
  );
}

export default CustomHeader;
