import React from "react";
import { Outlet } from "react-router-dom";
import CustomFooter from "@/layout_new/components/CustomFooter";
import CustomHeader from "@/layout_new/components/CustomHeader";
import styled from "styled-components";

const Layout = () => {
  return (
    <>
      <CustomHeader />
      <MarginTop>
        <Outlet />
      </MarginTop>
      <CustomFooter />
    </>
  );
};

export default Layout;

const MarginTop = styled.div`
  margin-top: 100px;

  @media only screen and (max-width: 600px) {
    margin-top: 60px;
  }
`;
