import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import { motion } from "framer-motion";
import React from "react";

// SliderItem 컴포넌트
const SliderItem = ({ image, title, description }) => {
  const navigate = useNavigate();
  return (
    <SliderItemWrapper $image={image}>
      <div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <p>{title}</p>
          <p>{description}</p>
          <>
            <BlueButton onClick={() => navigate("/reservation")} aria-label="딜러에게 견적받기">
              딜러에게 견적받기
            </BlueButton>
            <YellowButton onClick={() => navigate("/reservation")} aria-label="개인 간 거래하기">
              개인 간 거래하기
            </YellowButton>
          </>
        </motion.div>
      </div>
    </SliderItemWrapper>
  );
};

// 슬라이더 셋팅값
const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 7000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

// 슬라이더 데이터
const sliderData = [
  {
    image: "./images/main/new/main_imgslide_1.svg",
    title: "AI Data-Based Reliable Used Car Deal Carmonster",
    description: (
      <>
        인공지능 데이터 기반
        <br />
        믿을 수 있는 중고차 거래
        <br />
        카몬스터
      </>
    ),
  },
  {
    image: "./images/main/new/main_imgslide_2.svg",
    title: "Used Car Personal Transaction Liability System",
    description: (
      <>
        중고차 거래를 안전하게
        <br />
        개인거래 책임제
      </>
    ),
  },
];

const partners = [
  { id: 1, name: "한국자동자기술인협회" },
  { id: 2, name: "전국자동차성능평가협회" },
  { id: 3, name: "한국자동차소비자협회" },
  { id: 4, name: "카젠" },
  { id: 5, name: "공임나라" },
  { id: 6, name: "auto oasis" },
  { id: 7, name: "AJ카리안서비스" },
  { id: 8, name: "SEQUENCEN" },
  { id: 9, name: "주식회사 카옥션" },
  { id: 10, name: "NICE디앤알" },
  { id: 11, name: "보험개발원" },
  { id: 12, name: "한국교통안전공단" },
];

// 애니메이션 셋팅값
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.2,
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const Main = () => {
  const navigate = useNavigate();

  return (
    <>
      <Slider {...settings}>
        {sliderData.map((item, index) => (
          <SliderItem key={index} {...item} />
        ))}
      </Slider>

      <MainSection1>
        <div>
          <p>We Are</p>
          <motion.p
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            카몬스터는 신뢰할 수 있는 중고차 거래를 위해 노력합니다.
          </motion.p>
          <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }}>
            믿을 수 있는 중고차 시장을 위해 데이터 수집/분석을 통해 경매 및 개인 간 거래의 취약점을 파악/전략 수립하여
            중고차 거래를 투명하게 할 수 있도록 나아갑니다.
          </motion.p>
        </div>
      </MainSection1>

      <FloatingContainer>
        <img src="./images/main/new/car.svg" alt="icon1"></img>
      </FloatingContainer>

      <MainSection2>
        <div>
          <p>Company's value</p>
          <motion.p
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            카몬스터의 가치는 공감입니다.
          </motion.p>
          <MotionValueContainer variants={containerVariants} initial="hidden" whileInView="visible">
            <motion.div variants={textVariants}>
              <img src="./images/main/new/icon_car.svg" alt="icon1"></img>
              <h5>Trusted Network</h5>
              <p>전국카센터/진단협회/동호회의 다양한 데이터를 통해 정확한 정보를 전달합니다.</p>
            </motion.div>
            <motion.div variants={textVariants}>
              <img src="./images/main/new/icon_platform.svg" alt="icon2"></img>
              <h5>Commercial Platform</h5>
              <p>성능 진단플랫폼 소비자 중심 APP 데이터 기반 비대면 경매로 최적화된 환경을 제공합니다.</p>
            </motion.div>
            <motion.div variants={textVariants}>
              <img src="./images/main/new/icon_ai.svg" alt="icon3"></img>
              <h5>DATA / AI</h5>
              <p>빅데이터를 통한 인공지능 분석 데이터로 고객님께 맞춘 최적의 정보를 빠르게 제공합니다.</p>
            </motion.div>
          </MotionValueContainer>
        </div>
      </MainSection2>

      <MainSection3>
        <div>
          <p>Partner</p>
          <PartnerContainer>
            {partners.map((partner) => (
              <div key={partner.id}>
                <img src={`./images/main/new/partner/${partner.id}.png`} alt={partner.name} />
              </div>
            ))}
          </PartnerContainer>
          {/* <WhiteButton aria-label="파트너 더 보기">MORE</WhiteButton> */}
        </div>
      </MainSection3>

      <MainSection4 id="community">
        <div>
          <motion.p
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            귀사와 함께 성장을 하겠습니다.
          </motion.p>
          <BlackButton onClick={() => navigate("/community")}>Community</BlackButton>
        </div>
      </MainSection4>
    </>
  );
};

export default Main;

const Container = styled.section`
  display: flex;
  justify-content: center;

  & > div {
    flex: 1;
    max-width: 1280px;
    padding: 7rem 2rem;
  }
`;

const MainSection = styled(Container)`
  color: var(--black-color);
  text-align: center;

  & > div > p:first-child {
    color: #cf000e;
    font-size: 1.5rem;
    padding-bottom: 2.5rem;
  }
  & > div > p:nth-child(2) {
    font-weight: bold;
    font-size: 3rem;
    flex: 1;
    padding-bottom: 3.5rem;
  }
  & > div > p:nth-child(3) {
    font-size: 1.8rem;
    font-weight: 400;
    max-width: 950px;
    padding: 0 1.5rem;
    line-height: 1.5;
  }

  @media only screen and (max-width: 600px) {
    & > div > p:first-child {
      font-size: 1.5rem;
    }
    & > div > p:nth-child(2) {
      font-size: 2rem;
      line-height: 1.4;
      text-align: left;
    }
    & > div > p:nth-child(3) {
      font-size: 1.4rem;
      line-height: 1.5;
      text-align: left;
    }
  }
`;

const MainSection1 = styled(MainSection)`
  padding-bottom: 70px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const MainSection2 = styled(MainSection)`
  padding-top: 70px;
  background: var(--lgray-color);
`;
const MainSection3 = styled(MainSection)`
  overflow-x: hidden;
  /* 자식요소에 overflow-x: auto 있어서 */
`;

const MainSection4 = styled(Container)`
  padding: 5rem 0;
  text-align: center;
  color: var(--white-color);
  background-image: url("./images/main/new/write.svg");
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.9);

  p {
    font-size: 36px;
    font-weight: 300;
    padding-bottom: 3rem;
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 2rem;
      line-height: 1.4;
      text-align: left;
    }
  }
`;

////////// 서브 컨테이너
const SliderItemWrapper = styled.div`
  background-image: url(${(props) => props.$image});
  background-size: cover;
  background-position: center;
  height: 75vh;
  color: var(--white-color);

  /* 라이브러리 때문인지 자식에만 flex가 적용됨 */
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div > div {
    flex: 1;
    max-width: 1280px;
    padding: 0 2rem;
  }
  p:first-child {
    font-size: 1.5rem;
    font-weight: 400;
    padding-bottom: 1rem;
  }
  p:nth-child(2) {
    font-size: 3rem;
    line-height: normal;
    padding-bottom: 10rem;
  }

  @media only screen and (max-width: 600px) {
    p:first-child {
      font-size: 1.2rem;
      line-height: 1.3;
    }
    p:nth-child(2) {
      font-size: 1.8rem;
      line-height: 1.4;
      padding-bottom: 4rem;
    }
  }
`;

const FloatingContainer = styled.div`
  position: relative;
  height: 10rem; // 높이 조정
  margin-top: -5rem; // MainSection1과 겹치도록 위로 이동
  margin-bottom: -5rem; // MainSection2와 겹치도록 아래로 확장
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; // 다른 요소들 위에 표시되도록 함

  img {
    width: 20rem;
    height: auto;
  }
`;

const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    width: 23rem;
    aspect-ratio: 1/1;
    background: var(--white-color);
    padding: 3rem 2.7rem;
    margin: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 6rem;
    padding-bottom: 1rem;
  }
  h5 {
    font-size: 1.7rem;
    padding-bottom: 1rem;
  }
  p {
    padding: 0 !important;
    font-size: 1.4rem !important;
    font-weight: 300;
    line-height: 1.5;
  }

  @media only screen and (max-width: 600px) {
    & > div {
      width: 100%;
    }
  }
`;

const MotionValueContainer = motion(ValueContainer);

const PartnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;

  div {
    width: 18rem;
    border: 1px solid #dddddd;
    background: var(--white-color);
    padding: 1.2rem;
    margin: 0.5rem;
  }
  img {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    /* 가로 스크롤 */
    flex-wrap: nowrap;
    overflow-x: auto;

    div {
      flex: 0 0 auto;
      width: 10rem;
    }
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
`;

////////// Buttons
const Button = styled.button`
  width: 250px;
  height: 60px;
  border: 2px solid transparent !important;

  &:hover {
    border: 2px solid var(--white-color) !important;
    font-weight: 800 !important;
  }
`;

const YellowButton = styled(Button)`
  background: var(--yellow-color);
  color: var(--white-color);
`;

const BlueButton = styled(Button)`
  background: var(--blue-color);
  color: var(--white-color);
`;

const WhiteButton = styled(Button)`
  background: var(--white-color);
  color: var(--black-color);
  border: 1px solid var(--gray-color) !important;

  &:hover {
    background: var(--gray-color);
    color: var(--white-color);
    border: 1px solid var(--gray-color);
  }
`;

const BlackButton = styled(Button)`
  background: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color) !important;

  &:hover {
    background: var(--white-color);
    color: var(--black-color);
    border: 1px solid var(--black-color);
  }
`;
