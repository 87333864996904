import React from "react";
import styled from "styled-components";

import { PageHeader } from "@/layout_new/components/PageHeader";
import QnaForm from "@/layout_new/components/community/QnaForm";

const Community = () => {
  return (
    <>
      <PageHeader title="서비스 및 제휴문의" subtitle="카몬에 궁금하신 점이 있다면 문의주세요!" />
      <Section $padding="0rem 3rem 5.5rem 3rem">
        <Wrapper>
          <FlexBox>
            {/* <div>
              <SectionHeader>자주 묻는 질문</SectionHeader>
              <SectionSubHeader>카몬을 이용하실 때 알아두시면 좋습니다.</SectionSubHeader>
              <FAQAccordion data={FAQ} />
            </div> */}
            <div>
              {/* <SectionHeader>서비스 및 제휴문의</SectionHeader> */}
              {/* <SectionSubHeader>카몬에 궁금하신 점이 있다면 문의주세요!</SectionSubHeader> */}
              <QnaForm />
            </div>
          </FlexBox>
        </Wrapper>
      </Section>
    </>
  );
};

export default Community;

const Section = styled.section`
  display: flex;
  justify-content: center;

  background: ${(props) => props.$background || "white"};
  padding: ${(props) => props.$padding || "5.5rem 3rem"};

  @media only screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }

  span {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  overflow: hidden;
`;

const FlexBox = styled.div`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  & > div {
    flex: 1;
    padding: 2rem 1.5rem;

    @media only screen and (max-width: 600px) {
      padding: 1rem 0.5rem;
    }
  }

  & > div:nth-child(2) {
    @media only screen and (max-width: 600px) {
      padding: 3rem 0;
    }
  }
`;

const SectionHeader = styled.h1`
  color: var(--primary-text);
  font-size: 2.5rem;
  font-weight: bolder !important;
  padding: 0.5rem 0;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.8rem;
  }
`;

const SectionSubHeader = styled.h1`
  color: var(--yellow-color);
  font-size: 1.3rem;
  font-weight: bolder !important;
  padding: 1rem 0 2.5rem 0;
  text-align: center;
  line-height: 1.4;

  @media only screen and (max-width: 600px) {
    font-size: 1.1rem;
    padding: 0.3rem 0 2rem;
  }
`;
