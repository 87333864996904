import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { Button, Form, Header, Icon, Input, Message, Popup, TextArea } from "semantic-ui-react";
import { BASE_STYLES } from "@/Consts";

const QnaForm = () => {
  const [isQnaOpened, setIsQnaOpened] = useState(false);
  const [isQnaPosting, setIsQnaPosting] = useState(false);
  const [qnaPostingSuccess, setQnaPostingSuccess] = useState(false);
  const [qna, setQna] = useState({
    name: "",
    contact: "",
    content: "",
  });

  const postQna = () => {
    if (_.trim(qna.name) === "") return false;
    if (_.trim(qna.contact) === "") return false;
    if (_.trim(qna.content) === "") return false;

    setIsQnaPosting(true);

    axios
      .post("/apis/reservation/qna", qna)
      .then(({ data }) => {
        setQnaPostingSuccess(true);
        setQna({
          name: "",
          contact: "",
          content: "",
        });
      })
      .catch(({ response }) => {
        const { status, data } = response;
      })
      .finally(() => {
        setIsQnaPosting(false);
        setTimeout(() => setQnaPostingSuccess(false), 2000);
      });
  };

  return (
    <Form loading={isQnaPosting} success={qnaPostingSuccess}>
      <Form.Field>
        <label>문의자명</label>
        <Input
          style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
          value={qna.name}
          placeholder={"문의하시는 분 이름"}
          icon={
            qna.name !== "" && (
              <Icon
                name="remove"
                link
                onClick={() =>
                  setQna((prevState) => ({
                    ...prevState,
                    name: "",
                  }))
                }
              />
            )
          }
          onChange={(e, { value }) => {
            setQna((prevState) => ({
              ...prevState,
              name: value,
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>연락처</label>
        <Input
          style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
          value={qna.contact}
          onChange={(e, { value }) => {
            const refined = value
              .replace(/[^0-9]/g, "")
              .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3")
              .replace("--", "-");

            setQna((prevState) => ({
              ...prevState,
              contact: refined,
            }));
          }}
          maxLength={13}
          placeholder="문의하시는 분 연락처"
          icon={
            qna.contact !== "" && (
              <Icon
                name="remove"
                link
                onClick={() =>
                  setQna((prevState) => ({
                    ...prevState,
                    contact: "",
                  }))
                }
              />
            )
          }
        />
      </Form.Field>
      <Form.Field>
        <label>문의내용</label>
        <TextArea
          style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
          placeholder={"무엇이 궁금하신가요?"}
          rows={5}
          value={qna.content}
          onChange={(e, { value }) => {
            setQna((prevState) => ({
              ...prevState,
              content: value,
            }));
          }}
        />
      </Form.Field>
      {!qnaPostingSuccess && (
        <Message
          icon={"question circle outline"}
          header={"무엇이든 물어보세요!"}
          content={"확인 후 빠른 시일 내에 연락드리겠습니다."}
        />
      )}
      <Message success icon={"smile outline"} header={"접수완료"} content={"고객님의 문의가 접수되었습니다."} />
      <Popup
        wide
        open={isQnaOpened}
        onOpen={() => setIsQnaOpened(true)}
        onClose={() => setIsQnaOpened(false)}
        inverted
        position={"top right"}
        trigger={
          <Button
            fluid
            content={"카몬에 문의하기"}
            color={"yellow"}
            loading={isQnaPosting}
            disabled={!(_.trim(qna.name) !== "" && _.trim(qna.contact) !== "" && _.trim(qna.content) !== "")}
            onClick={() => setIsQnaOpened(true)}
          />
        }
        on="click"
      >
        <Header>
          <Icon name={"send"} />
          <Header.Content>
            문의사항을 등록하시겠습니까?
            <Header.Subheader>정확한 연락을 위해 연락처를 꼭 확인해주세요.</Header.Subheader>
          </Header.Content>
        </Header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button content={"아니오"} onClick={() => setIsQnaOpened(false)} />
          <Button
            icon={"check"}
            content={"네"}
            primary
            onClick={() => {
              setIsQnaOpened(false);
              postQna();
            }}
          />
        </div>
      </Popup>
    </Form>
  );
};

export default QnaForm;
