import React from "react";
import styled from "styled-components";
import Timeline from "@/layout_new/components/aboutCompany/Timeline";
import { PageHeader } from "@/layout_new/components/PageHeader";

const AboutCompany = () => {
  return (
    <>
      <PageHeader title="회사 소개" subtitle="새로운 중고차 거래 문화를 만드는 카몬" />
      <Section $padding="4rem 3rem 1rem 3rem">
        <Wrapper>
          <Content>
            <FontRed>카몬스터</FontRed>는 전국 카센터 및 진단점과 협력하여 차량 데이터 기반으로 최고가 판매와 믿을 수
            있는 중고차 거래를 만들어 갑니다.
          </Content>
        </Wrapper>
      </Section>
      <Section $padding="2rem 3rem 4rem 3rem">
        <Wrapper>
          <Title>연혁</Title>
          <Timeline />
        </Wrapper>
      </Section>
    </>
  );
};

export default AboutCompany;

const Section = styled.section`
  display: flex;
  justify-content: center;

  background: ${(props) => props.$background || "white"};
  padding: ${(props) => props.$padding || "5.5rem 3rem"};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }

  span {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  overflow: hidden;
`;

const Title = styled.h1`
  color: ${(props) => props.$color || "black"} !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  padding: 0.5rem 0;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem !important;
  }
`;

const Content = styled.p`
  font-size: 1.4rem;
  font-weight: 400 !important;
  line-height: 1.7;
  padding: 1.5rem 2rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.1rem;
    line-height: 1.5;
  }
`;

const FontRed = styled.span`
  color: var(--red-color);
  font-weight: bold !important;
`;
