import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import VideoGrid from "@/layout_new/components/Issue/VideoGrid";
import NewsList from "@/layout_new/components/Issue/NewsList";
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import { PageHeader } from "@/layout_new/components/PageHeader";

const Issue = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentTab, setCurrentTab] = useState("홍보영상");

  useEffect(() => {
    axios.get("/apis/common/news").then(({ data }) => setNewsData(data));
  }, []);

  return (
    <>
      <PageHeader title="뉴스" subtitle="언론이 주목하는 카몬" />
      <Section>
        <Wrapper>
          <Tabs
            mt="30px"
            position="relative"
            variant="unstyled"
            onChange={(index) => setCurrentTab(index === 0 ? "홍보영상" : "보도자료")}
          >
            <TabList justifyContent="center">
              <Tab>홍보영상</Tab>
              <Tab>보도자료</Tab>
            </TabList>
            <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
            <TabPanels mt="50px">
              <TabPanel>
                <VideoGrid />
              </TabPanel>
              <TabPanel>
                <NewsList newsData={newsData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Wrapper>
      </Section>
    </>
  );
};

export default Issue;

const Section = styled.section`
  display: flex;
  justify-content: center;

  background: ${(props) => props.$background || "white"};
  padding: ${(props) => props.$padding || "0 3rem 5.5rem 3rem"};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }

  span {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  overflow: hidden;
`;
