import {Header} from 'semantic-ui-react';
import React from 'react';
import _ from 'lodash';

export default function DescriptionHeader({header, subheader, description}) {
  return (
    <Header>
      <Header.Content className={'description-header'}>
        {
          _.map(header, (hd, idx) => (
            <React.Fragment key={`H${idx}`}>
              {hd}
              {(idx < header.length - 1) && <br />}
            </React.Fragment>
          ))
        }
        <div style={{padding: '3px 0'}} />
        <Header.Subheader className={'description-subheader'}>
          {
            _.map(subheader, (shd, idx) => (
              <React.Fragment key={`SH${idx}`}>
                {shd}
                {(idx < header.length - 1) && <br />}
              </React.Fragment>
            ))
          }
        </Header.Subheader>
      </Header.Content>
      <div style={{padding: '20px 0'}} />
      <p className={'description-text'}>
        {
          _.map(description, (desc, idx) => (
            <React.Fragment key={`D${idx}`}>
              {desc}
              {(idx < header.length - 1) && <br />}
            </React.Fragment>
          ))
        }
      </p>
    </Header>
  );
}
